import Vue from 'vue'

export default class VueNestedObject {
  reactiveDelete<T extends object, K extends KeysOf<T>> (item: T, key: K) {
    Vue.delete(item, key)
  }

  reactiveSet<T extends object, K extends KeysOf<T>> (item: T, key: K, value: T[K]) {
    Vue.set(item, key, value)
  }

  createObjectIfNotSetWithItem<T extends object> (item: T, key: ObjectKeys<T>) {
    if (!this.objectIsSet(item[key])) {
      this.reactiveSet(item, key, ({} as T[ObjectKeys<T>]))
    }
  }

  objectIsSet (obj: any): boolean {
    return obj !== null && obj !== undefined && typeof obj === 'object'
  }
}

<template>
  <td>
    <v-row no-gutters class="align-center justify-center fill-height expand-checkbox">
      <v-col cols="6">
        <v-checkbox
            primary
            hide-details
            v-model="checkBoxSelected"
            @change="selectAllInstructionsIO(props.item.io)"
            :class="isHover || checkBoxSelected ? 'checkBoxHover mt-0' : 'checkBoxNotHover mt-0'"
        ></v-checkbox>
      </v-col>
      <v-col class="keep-width-expand py-0" cols="6">
        <div v-if="isHover">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="expand-arrow" @click="props.expand(!props.isExpanded)">
                <v-icon v-ripple="{ class: `primary--text`, center: true }" class="elevation-3 selenium-expand-button" v-if="!props.isExpanded" small>expand_more</v-icon>
                <v-icon v-ripple="{ class: `primary--text`, center: true }" class="elevation-3" v-if="props.isExpanded" small>expand_less</v-icon>
              </div>
            </template>
            <span>{{props.isExpanded ? 'decrease row' : 'expand row'}}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
  </td>
</template>

<script>
export default {
  name: 'ExpandIOFragment',
  props: ['props', 'isHover'],
  components: {

  },
  data: function () {
    return {
      checkBoxSelected: false
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    selectAllInstructionsIO (io) {
      this.$emit('ask-select-item-and-kpi', this.props.item.id, this.props.item, this.checkBoxSelected)
      this.$emit('ask-select-instructions', this.props.item.id_list, this.checkBoxSelected)
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>

.keep-width-expand {
  width: 8em;
  text-align: center;
}
</style>

<template>
    <td class="objectives-fragment">
      <v-row v-if="objectiveValidation" wrap>
          <v-col cols="12" v-for="(item, index) in objectives" :key="index">
            <AlignLabel
              :little="true"
              :label="item.label"
              :chipStyle="false"
            >
               {{item.value}}
            </AlignLabel>
          </v-col>
      </v-row>
    </td>
</template>

<script>
import { AlignLabel } from '../../Tools'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'ObjectivesFragment',
  props: ['props'],
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {
      objectivesConfig: [
        {
          label: 'KPI CPA',
          value: 'objective.KPI_CPA'
        },
        {
          label: 'Offset',
          value: 'objective.offset'
        },
        {
          label: 'Max bid',
          value: 'objective.max_CPM'
        },
        {
          label: 'Max budget',
          value: 'objective.max_budget'
        },
        {
          label: 'Min budget',
          value: 'objective.min_budget'
        }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    objectiveValidation: function () {
      return this.props.item.objective !== undefined && this.props.item.objective !== '' && this.props.item.objective !== null && typeof this.props.item.objective === 'object'
    },
    objectives: function () {
      let objectiveFiltered = this.objectivesConfig.filter(item => {
        let propKeys = item.value.split('.')
        if (!this.checkElement(this.props.item[propKeys[0]]) || !this.checkElement(this.props.item[propKeys[0]][propKeys[1]])) {
          return false
        }

        return true
      })

      return objectiveFiltered.map(item => {
        let propKeys = item.value.split('.')
        return {
          label: item.label,
          value: this.props.item[propKeys[0]][propKeys[1]]
        }
      })
    }
  },
  watch: {

  }
}
</script>

<style>
.objectives-fragment {
  width: 25em;
}
</style>

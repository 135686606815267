<template>
    <td class='conversion-funnel-td'>

      <div class='div-kpi-funnel'>
        <LabelLine :mini="true" :label="`KPI to optimize : `">{{kpiToOptimize}}</LabelLine>
        <br>
        <AttributionWindow
          :props="props"
        >
        </AttributionWindow>

        <ConversionFunnelComponent
          v-if="displayConvertionFunnel"
          :conversion-funnel="props.item.true_conversion_funnel_pixel_ids"
          :nb-displayed-funnels="nbDisplayedFunnels"
          :nb-displayed-pixels="nbDisplayedPixels"
        >
        </ConversionFunnelComponent>
      </div>

      <div v-if="clickToShowMoreNeeded && typeof props.item.true_conversion_funnel_pixel_ids === 'object'">
      <v-menu

        top offset-y

      >

      <template v-slot:activator="{ on }">
        <div  v-on="on" class="mini-info-text">Click to show more</div>
      </template>

        <div class="pixel-v-menu">
          <LabelLine :mini="true" :label="`KPI to optimize : `">{{kpiToOptimize}}</LabelLine>
          <v-divider></v-divider>
          <AttributionWindow
            :props="props"
          >
          </AttributionWindow>

          <ConversionFunnelComponent
            v-if="displayConvertionFunnel"
            :conversion-funnel="props.item.true_conversion_funnel_pixel_ids"
          >
          </ConversionFunnelComponent>
        </div>
      </v-menu>
      </div>
    </td>
</template>

<script>
import LabelLine from '../../Tools/LabelLine'
import AttributionWindow from './AttributionWindow'
import ConversionFunnelComponent from '../../../Common/ConversionFunnelComponent'

export default {
  name: 'PixelIdFragment',
  props: ['props'],
  components: {
    LabelLine,
    AttributionWindow,
    ConversionFunnelComponent
  },
  mixins: [],
  data: function () {
    return {
      nbDisplayedFunnels: 1,
      nbDisplayedPixels: 1
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    displayConvertionFunnel: function () {
      return this.props.item.true_conversion_funnel_pixel_ids !== undefined &&
        this.props.item.true_conversion_funnel_pixel_ids !== null &&
        Object.keys(this.props.item.true_conversion_funnel_pixel_ids).length > 0 &&
        ['CPA', 'CPA_PC'].indexOf(this.props.item.true_KPI_to_optimize) !== -1
    },
    clickToShowMoreNeeded: function () {
      if (typeof this.props.item.true_conversion_funnel_pixel_ids !== 'object') {
        return false
      }

      if (Object.keys(this.props.item.true_conversion_funnel_pixel_ids).length > this.nbDisplayedFunnels) {
        return true
      }

      for (let key in this.props.item.true_conversion_funnel_pixel_ids) {
        if (this.props.item.true_conversion_funnel_pixel_ids[key].length > this.nbDisplayedPixels) {
          return true
        }
      }

      return false
    },
    kpiToOptimize: function () {
      if (this.$isNotNullOrUndefined(this.props.item.true_KPI_to_optimize) && this.props.item.true_KPI_to_optimize !== '') {
        return this.$commonUtils.kpiValueToKpiText(this.props.item.true_KPI_to_optimize)
      } else {
        return 'NC'
      }
    }
  },
  watch: {
  }
}
</script>

<style>
.conversion-funnel-td .v-small-dialog a {
    display: block;
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    height: auto;
    text-decoration: inherit;
    background: white;
}

.conversion-funnel-td .div-kpi-funnel {
  padding-top: 1em;
  padding-bottom: 1em;
}
</style>

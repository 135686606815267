<template>
  <td>
    <AlignLabel v-if="toDisplay.client"
      :label="getDspClientLabel"
    >
       {{getDspClient}}
    </AlignLabel>
    <AlignLabel v-if="toDisplay.advertiser"
     :label="getAdLabelPerDsp">
      {{getAdField}}
    </AlignLabel>
    <AlignLabel v-if="toDisplay.io"
      :label="getInsertionOrderLabelPerIo"
    >
       {{getIo}}
    </AlignLabel>
  </td>
</template>

<script>
import { generalMixin } from '../../../../mixins/generalMixin'
import { AlignLabel } from '../../Tools'

export default {
  name: 'Localization',
  props: {
    props: Object,
    toDisplay: {
      type: Object,
      default: function () {
        return {
          client: true,
          advertiser: true,
          io: true
        }
      }
    }
  },
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {
    }
  },
  created: function () {
    // allow to run test
    if (this.$_TEST) this.dsp = this.$APPNEXUS
  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    getDspClient () {
      return this.props.item[this.getClientPerDsp]
    },
    getAdField () {
      return this.props.item[this.getAdFieldPerDsp]
    },
    getIo () {
      if (this.dsp === this.$BEESWAX) {
        return this.props.item.io.replace(`${this.props.item.buzz_key}_`, '')
      }
      return this.props.item.io
    }
  },
  watch: {
  }
}
</script>

<style>
</style>

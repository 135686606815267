import { BetaInstructionDsp, InstructionDsp } from './instruction_type'
import {
  $APPNEXUS,
  $MEDIAMATH,
  $DBM,
  $THETRADEDESK,
  $YOUTUBE,
  $BEESWAX,
  $FACEBOOK,
  $KAYZEN,
  $META
} from '../config/dspConfig'

const ALL_DSP = [$APPNEXUS, $MEDIAMATH, $DBM, $THETRADEDESK, $YOUTUBE, $BEESWAX, $FACEBOOK, $KAYZEN, $META]

export function isDsp (str: string): str is InstructionDsp {
  return ALL_DSP.includes(str as InstructionDsp)
}

export function isBetaDsp (str: string): str is BetaInstructionDsp {
  return str === $META
}

<template>
    <td>
      <v-row v-if="objectiveStrategyValidation" wrap>
          <v-col cols="12" v-for="(item, index) in objectiveStrategy" :key="index">
            <AlignLabel
              :label="item.label"
              :chipStyle="false"
            >
               {{ !item.isPercentage ? item.value : Math.round(item.value * 100).toString() + ' %' }}
            </AlignLabel>
          </v-col>
      </v-row>
    </td>
</template>

<script>
import { AlignLabel } from '../../Tools'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'ObjectiveStrategy',
  props: ['props'],
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {
      objectiveStrategyConfig: [
        {
          label: 'Viz filter',
          value: 'objective.min_viz',
          isPercentage: this.$route.params.dsp !== this.$THETRADEDESK,
          isDisplay: () => {
            if (![this.$THETRADEDESK, this.$DBM].includes(this.dsp)) {
              return true
            }
            return this.compOverwriteViewability
          }
        },
        {
          label: 'Viz video filter',
          value: 'objective.min_video_viz',
          isDisplay: () => {
            if (this.dsp !== this.$THETRADEDESK) {
              return false
            }
            return this.compOverwriteViewability
          }
        },
        {
          label: 'Attribution ratio',
          value: 'opti_ratio'
        }
      ],
      isPercentage: [
        'Completion filter',
        'Viz filter'
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    compOverwriteViewability () {
      return this.dsp === this.$DBM ? !this.props.item.keep_min_viz : this.props.item.overwrite_viewability
    },
    objectiveStrategyValidation: function () {
      return this.checkElement(this.props.item.objective)
    },
    objectiveStrategy: function () {
      return this.computedProcess(this.objectiveStrategyConfig.filter(item => typeof item.isDisplay !== 'function' || item.isDisplay()))
    }
  },
  watch: {

  }
}
</script>

<style>
  .attribution-ratio-info {
    color: darkred !important;
    font-size: 12px;
    padding-left: 1em;
  }
</style>

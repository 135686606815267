
import Component, { mixins } from 'vue-class-component'
import { KpiValue, KpiText } from '../../../../../types/brief_enum'
import { rulesMixin } from '../../../../mixins/rulesMixin'
import RemoveScibidsModel, { RemoveScibidsApplyStrategyModel } from '../../../../models/RemoveScibids/RemoveScibidsModel'
import { $APPNEXUS, $BEESWAX, $DBM, $THETRADEDESK, $YOUTUBE } from '../../../../../config/dspConfig'
import { Prop, Watch } from 'vue-property-decorator'
import { Instruction, InstructionDsp } from '../../../../../types/instruction_type'

export type applyStratCombosType = { fields: Array<string>, rule: () => boolean }

export enum Combos {
  BASE_BID = 0,
  KPI = 1,
  KPI_AND_TARGET = 2,
  ALL_OF_THEM = 3
}

@Component({})
export default class RemoveScibidsForm extends mixins(rulesMixin) {
  @Prop({ required: true }) ioID: string
  @Prop({ required: true }) memberExternalID: number
  @Prop({ required: true }) dsp: InstructionDsp
  @Prop({ required: true }) removeScibidsModel: RemoveScibidsModel

  removalReasonItems: Array<{ [key: string]: any }> = [
    { text: 'Constraints Not Respected', value: 'CONSTRAINTS_NOT_RESPECTED' },
    { text: 'Pacing / Delivery', value: 'PACING_DELIVERY' },
    { text: 'KPI Not Reached', value: 'KPI_NOT_REACHED' },
    { text: 'Low Margins', value: 'LOW_MARGINS' },
    { text: 'Other', value: 'OTHER' }
  ]
  applyStratComboItems: { [key: string]: Array<{ text: string, value: number }> } = {
    [$APPNEXUS]: [
      { text: 'Default Xandr CPM', value: null },
      { text: 'Edit KPI', value: Combos.KPI_AND_TARGET }
    ],
    [$DBM]: [
      { text: 'Default DBM CPC', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID },
      { text: 'Edit KPI', value: Combos.KPI }
    ],
    [$YOUTUBE]: [
      { text: 'No optimization change', value: null }
    ],
    [$THETRADEDESK]: [
      { text: 'No optimization change', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID },
      { text: 'Edit KPI', value: Combos.KPI_AND_TARGET },
      { text: 'Edit Base Bid & KPI', value: Combos.ALL_OF_THEM }
    ],
    [$BEESWAX]: [
      { text: 'No optimixzation change', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID }
    ]
  }
  dspObjectiveItems: { [key: string]: Array<any> } = {
    [$APPNEXUS]: [
      { text: KpiText.VTR, value: KpiValue.VTR },
      { text: KpiText.CTR, value: KpiValue.CTR },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText.CPCV, value: KpiValue.CPCV },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM }
    ],
    [$DBM]: [
      { text: KpiText.CPA, value: KpiValue.CPA },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM },
      { text: KpiText.CPIAVC, value: KpiValue.CPIAVC }
    ],
    [$YOUTUBE]: [],
    [$THETRADEDESK]: [
      { text: KpiText.VTR, value: KpiValue.VTR },
      { text: KpiText.CTR, value: KpiValue.CTR },
      { text: KpiText.CPA, value: KpiValue.CPA },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText.CPCV, value: KpiValue.CPCV },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM }
    ],
    [$BEESWAX]: []
  }
  errorWhenApply: boolean = false
  isUserAllowed: boolean = false

  mounted () {
    this.setTmpAccess()
  }

  // TMP FUNCTION TO CHECK ACCESS BEFORE THE FEATURE IS COMPLETELY DONE (AUGUST 2023 - (?))
  setTmpAccess () {
    const roles = this.$store.getters.getCurrentUserRoles
    this.isUserAllowed = roles.includes('api.v1.remove_scibids.dsp.member.insertion_order:post')
  }

  async apply () {
    if (!this.checkRequiredField()) {
      this.errorWhenApply = true
      return false
    }
    this.errorWhenApply = false
    await this.sendRemoveScibidsPostRequest()
    this.close()
  }
  close () {
    this.$emit('close')
  }

  // REQUEST
  async sendRemoveScibidsPostRequest () {
    const response = await this.$apiCaller.postRemoveScibids(
      this.dsp,
      this.memberExternalID,
      this.ioID,
      this.removeScibidsModel.getAsApiArgs()
    )
    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.$store.commit('setSuccessMessageWithResponse', response)
    }
  }

  checkRequiredField (): boolean {
    const checkers = [
      [this.isBaseBidRequired, this.removeScibidsModel.removalParameters.applyStrategy.baseBid],
      [this.isDspObjectiveRequired, this.removeScibidsModel.removalParameters.applyStrategy.dspObjective],
      [this.isDspTargetRequired, this.removeScibidsModel.removalParameters.applyStrategy.dspObjectiveTarget]
    ]
    let check: boolean = true
    checkers.forEach((fn: Array<any>) => {
      if (fn[0] && fn[1] == null) {
        check = false
      }
    })
    return check && this.removeScibidsModel.removalReason != null
  }

  // GETTERS
  get getDspObjectiveItemsChoice (): Array<any> {
    return this.dspObjectiveItems[this.dsp]
  }
  get isApplyStratComboSelected (): boolean {
    return this.removeScibidsModel.removalParameters.applyStrategy.comboSelected != null
  }
  get isBaseBidRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.BASE_BID, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isDspObjectiveRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.KPI, Combos.KPI_AND_TARGET, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isDspTargetRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.KPI_AND_TARGET, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isKpiSelectedInPercentageMode (): boolean {
    const applyStrat = this.removeScibidsModel.removalParameters.applyStrategy
    return applyStrat != null && applyStrat.dspObjective != null && [KpiValue.VTR as string, KpiValue.CTR as string].includes(applyStrat.dspObjective)
  }

  // WATCHERS
  @Watch('removeScibidsModel.removalParameters.applyStrategy.comboSelected')
  onComboSelectedChange () {
    const applyStratModel: RemoveScibidsApplyStrategyModel = this.removeScibidsModel.removalParameters.applyStrategy
    if (applyStratModel != null) {
      applyStratModel.resetValues()
    }
  }
}

<template>
  <LabelLine v-if="displayAttributionWindow" :mini="true" :label="`Attribution window : `">
  {{attributionWindow}}
  </LabelLine>
</template>

<script>
import LabelLine from '../../Tools/LabelLine'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'AttributionWindow',
  props: ['props'],
  mixins: [generalMixin],
  components: {
    LabelLine
  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    getTimeUnit (minute) {
      let PvTimeUnit = minute % this.$MINUTES_IN_A_DAY === 0
        ? 'day'
        : 'minute'
      if (PvTimeUnit === 'day') {
        return (minute / this.$MINUTES_IN_A_DAY).toString() + ' d'
      }
      return minute + ' min'
    }
  },
  computed: {
    attributionWindow: function () {
      if (!this.checkElement(this.props.item.objective) ||
          (!this.checkElement(this.props.item.objective.true_PV_window) && !this.checkElement(this.props.item.objective.true_PC_window))) {
        return 'As set in the DSP'
      }

      if (this.checkElement(this.props.item.objective.true_PV_window) && this.props.item.objective.true_PV_window === 0 && !this.checkElement(this.props.item.objective.true_PC_window)) {
        return 'post click only'
      }

      let strToReturn = []

      if (this.checkElement(this.props.item.objective.true_PV_window)) {
        strToReturn.push('PV : ' + this.getTimeUnit(this.props.item.objective.true_PV_window))
      }

      if (this.checkElement(this.props.item.objective.true_PC_window)) {
        strToReturn.push('PC : ' + this.getTimeUnit(this.props.item.objective.true_PC_window))
      }

      return strToReturn.join(' | ')
    },
    displayAttributionWindow: function () {
      return this.attributionWindow !== null && this.attributionWindow !== 'As set in the DSP'
    }
  },
  watch: {

  }
}
</script>

<style>
</style>

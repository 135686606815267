<template>
  <td class="group-name-fragment">

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <span v-if="typeof props.item.group_name === 'string'">
                {{props.item.group_name.substring(0, groupNameMaxLength)}} {{ props.item.group_name.length >= groupNameMaxLength ? `...` : ``}}
            </span>
            </span>
        </template>
        <span>{{props.item.group_name}}</span>
      </v-tooltip>
      <CopyClipboardComponent
        :toCopy="props.item.group_name"
        :tooltipText="'Copy group name to clipboard.'"
        iconColor="gray"
        :btnSmall="true"
        :elevation="false"
        color="transparent"
        icon-size="12px"
      >

      </CopyClipboardComponent>
      <br>
      <br>
      <LabelLine
        :label="'Id'">
        {{props.item.id}}
      </LabelLine>

      <CopyClipboardComponent
        :toCopy="props.item.id"
        :tooltipText="'Copy id to clipboard.'"
        iconColor="gray"
        :btnSmall="true"
        :elevation="false"
        color="transparent"
        icon-size="12px"
      >
      </CopyClipboardComponent>
      <br>
      <br>
      <LabelLine
        :label="'Insertion date'">
        {{transformInsertionDate}}
      </LabelLine>

  </td>
</template>

<script>
import { LabelLine } from '../../Tools'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'

export default {
  name: 'GroupNameFragment',
  props: ['props'],
  components: {
    LabelLine,
    CopyClipboardComponent
  },
  data: function () {
    return {
      groupNameMaxLength: 42
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    transformInsertionDate: function () {
      let isoDate = this.props.item.insertion_date

      return this.$commonUtils.transformIsoDate(isoDate)
    }
  },
  watch: {
  }
}
</script>

<style>

.group-name-fragment {
  width: 25em;
}
</style>

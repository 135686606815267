
// VUE
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
// MIXINS
import { callInstructionsOptiMixin } from '../../../mixins/callInstructionsOptiMixin'
// MODELS
import BriefModel from '../../../models/Briefs/BriefModel'
import OverviewModel from '../../../models/Overview/OverviewModel'
// EXTERNAL COMPONENTS
import WaitBar from '../../../components/Common/WaitBar.vue'
import { LoadingTemplate } from '../../AlertingComponents/Sheet/SheetFragments/AlertTypeTemplate'
// UTILS
import { clearInterval, setInterval } from 'timers'
import { dspConfig } from '../../../../config/dspConfig'
import { InstructionDsp } from '../../../../types/instruction_type'

export type BriefStatusType = 'DRAFT' | 'TO_VALIDATE' | 'VALIDATED'
export type BriefWithOverviewType = BriefModel & { overview: OverviewModel, ioStatus: BriefStatusType }
export const INSTRUCTION_STATUS_DATA: { [key: string]: { text: string, css: string } } = {
  'DRAFT': { text: 'Draft', css: 'instruction-status-orange' },
  'REMOVED': { text: 'Draft', css: 'instruction-status-orange' },
  'TO_VALIDATE': { text: 'In review', css: 'instruction-status-green' },
  'VALIDATED': { text: 'Validated', css: 'instruction-status-green' }
}

@Component({
  components: {
    WaitBar,
    LoadingTemplate
  }
})
export default class BulkAddBriefListingComponent extends mixins(callInstructionsOptiMixin) {
  @Prop({ required: true }) dataFormGroupKey: any

  bulkAddId: number = null

  allBriefs: Array<BriefWithOverviewType> = null
  currentBriefs: Array<BriefWithOverviewType> = null
  loading: boolean = true

  intervalTimer: any = null
  intervalTimerDelay: number = 10000 // default 10000 (ms, = 10 seconds)

  pagination: any = {}
  rowsPerPage: number = 10 // default 10
  loadingPage: boolean = false

  // TABLE
  headers: Array<any> = [
    { text: 'ID', value: 'overview.externalId', cols: '2', sortable: true },
    { text: 'IO name', value: 'overview.name', cols: '5', sortable: true },
    { text: 'Status', value: 'overview.status', cols: '2' },
    { text: '', sortable: false, cols: '3' }
  ]

  async mounted () {
    this.pagination.page = 1
    this.bulkAddId = Number(this.dataFormGroupKey.group_key.entity_id)
    await this.getBriefsFromBulkAddID()
    this.pagination.maxPage = Math.ceil(this.allBriefs.length / this.rowsPerPage)
    this.setCurrentBriefs()
    await this.checkIOstatus()
    this.intervalTimer = setInterval(() => {
      this.checkIOstatus()
    }, this.intervalTimerDelay)
    this.loading = false
  }

  clearInterval () {
    clearInterval(this.intervalTimer)
  }

  beforeDestroy () {
    this.clearInterval()
  }

  // API GETTERS
  async getBriefsFromBulkAddID () {
    let response = null
    response = await this.$apiCaller.getBulkAddBriefs(this.bulkAddId)
    if (this.$apiCaller.isResponseError(response)) {
      console.warn('Error when calling api getBulkAddBriefs')
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.allBriefs = []
      response.data.forEach((data: any) => {
        let elem = new BriefModel(null, data);
        (elem as BriefWithOverviewType).overview = new OverviewModel(data.overview);
        (elem as BriefWithOverviewType).ioStatus = null
        this.allBriefs.push(elem as BriefWithOverviewType)
      })
    }
  }

  // LINK
  async openIoForm (brief: BriefWithOverviewType) {
    const result = await this.callInstructionOptiReturnValue(brief.overview.externalId)
    if (result == null || result.length === 0) {
      window.open(this.openNewIoForm(brief).href, '_blank').focus()
      return
    }
    window.open(this.openEditIoForm(brief).href, '_blank').focus()
  }
  openNewIoForm (brief: BriefWithOverviewType) {
    let dataFormToSend = {
      group_key: {
        id: brief.id,
        advertiser_id: String((brief.overview.advertiser as any).external_id),
        client_id: this.dataFormGroupKey.group_key.client_id,
        dsp: brief.overview.subDsp,
        insertion_order_id: String(brief.overview.externalId),
        entity_id: 'NONE',
        entity_type: 'NONE'
      },
      group_name: String(brief.overview.name)
    }
    return this.$router.resolve({
      name: 'table',
      params: this.$route.params,
      query: {
        data_form: JSON.stringify(dataFormToSend)
      }
    })
  }
  openEditIoForm (brief: BriefWithOverviewType) {
    return this.$router.resolve({
      name: 'table',
      params: {
        dialogId: brief.overview.externalId,
        dsp: brief.overview.subDsp,
        openDialog: 'ioForm'
      }
    })
  }

  // INTERVAL API CALLS TO REFRESH BRIEF STATUS
  setIOStatus (data: any) {
    if (data == null || data.length === 0) {
      return
    }
    const dsp = this.dsp
    if (dsp == null) { return }
    const IOfieldName: string = dspConfig[dsp.toLowerCase() as InstructionDsp].newIoField // Using newIoField
    const receivedInstrus = data.map((ioList: Array<any>) => {
      return ioList[0]
    }).filter((io: any) => {
      return io != null && IOfieldName in io && io[IOfieldName] != null
    })
    // If io is here, check its status, otherwise it means it doesn't exists, so its status is "Not created"
    this.allBriefs.forEach((briefToModify: BriefWithOverviewType) => {
      const instruID = briefToModify.overview.externalId
      briefToModify.ioStatus = (
        receivedInstrus.map((ioID: any) => ioID[IOfieldName].toString()).includes(instruID)
          ? receivedInstrus.filter((io: any) => io[IOfieldName].toString() === instruID)[0].status
          : null // Not instru received from API call means it does not exist in Datastore, status is "Not Created", so null
      )
    })
  }

  async checkIOstatus () {
    let callArray: Array<Promise<any>> = []
    const offsetStart = (this.pagination.page - 1) * this.rowsPerPage
    const offsetEnd = (this.pagination.page) * this.rowsPerPage
    const displayedBriefs = [...this.allBriefs].slice(offsetStart, offsetEnd)
    displayedBriefs.forEach((brief: BriefWithOverviewType) => {
      callArray.push(this.callInstructionOptiReturnValue(brief.overview.externalId, false))
    })
    const response: Array<any> = await Promise.all(callArray)
    this.setIOStatus(response)
    console.warn('Check bulk add instructions status called')
  }

  // PAGINATION
  setCurrentBriefs () {
    const first = this.firstElementRealIndex()
    const last = this.lastElementRealIndex()
    this.currentBriefs = this.allBriefs.slice(first, last + 1)
  }
  firstElementRealIndex (): number {
    return (this.pagination.page - 1) * this.rowsPerPage
  }
  lastElementRealIndex (): number {
    return (
      this.pagination.page < this.pagination.maxPage
        ? (this.pagination.page * this.rowsPerPage) - 1
        : this.allBriefs.length - 1
    )
  }
  async goPrevPage () {
    if (this.pagination.page > 1) {
      this.loadingPage = true
      this.pagination.page -= 1
      this.setCurrentBriefs()
      await this.checkIOstatus()
      this.loadingPage = false
    }
  }
  async goNextPage () {
    if (this.pagination.page < Math.ceil(this.allBriefs.length / this.rowsPerPage)) {
      this.loadingPage = true
      this.pagination.page += 1
      this.setCurrentBriefs()
      await this.checkIOstatus()
      this.loadingPage = false
    }
  }

  // BRIEF STATUS
  ioStatusCSSclass (status: string): string {
    return status in INSTRUCTION_STATUS_DATA ? INSTRUCTION_STATUS_DATA[status].css : 'instruction-status-red'
  }
  briefStatusText (status: string): string {
    return status in INSTRUCTION_STATUS_DATA ? INSTRUCTION_STATUS_DATA[status].text : 'Not created'
  }
  setOrEditText (status: string): string {
    return status in INSTRUCTION_STATUS_DATA ? `edit in a new tab` : `set in a new tab`
  }

  // GETTERS
  get dsp (): string {
    return this.allBriefs != null && this.allBriefs.length > 0
      ? this.allBriefs[0].overview.subDsp
      : null
  }
}

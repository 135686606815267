<template>
  <td>
    <v-row no-gutters wrap class="ma-0">
      <v-col v-for="(item, index) in overwrited" :key="index" cols="6" class="pa-0">
        <v-row no-gutters wrap class="mr-0">
          <v-col cols="10" class="pr-0">
            <v-tooltip bottom :disabled="!item.tooltip">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <LabelLine :class="{advancedItem: item.isAdvanced}" :mini="true" :label="item.label + ' :'">
                  </LabelLine>
                </div>
              </template>
              <span>
                {{item.tooltip}}
              </span>
            </v-tooltip>

          </v-col>
          <v-col cols="2" class="pl-0">
            <v-tooltip bottom :disabled="!item.tooltip">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon :class="item.isOrange ? 'item-is-in-between' : isOn(item) ? 'item-is-on' : 'item-is-off'" small>
                    power_settings_new
                  </v-icon>
                </div>
              </template>
              <span>{{item.tooltip}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </td>
</template>

<script>
import LabelLine from '../../Tools/LabelLine'

function tooltipFunction (props) {
  return __tooltipManager(props, 'normal')
}

function tooltipFunctionTarget (props) {
  return __tooltipManager(props, 'target')
}

function __tooltipManager (props, mode) {
  let maxFreqString = ''
  let frequencyType = ''
  let overwriteName = ''

  if (mode === 'normal') {
    frequencyType = 'max_frequency'
    overwriteName = 'overwrite_frequency'
  } else if (mode === 'target') {
    frequencyType = 'target_frequency'
    overwriteName = 'overwrite_frequency_target'
  } else {
    throw Error('Not implemented !')
  }

  if (props.item[frequencyType] !== undefined && props.item[overwriteName] && Object.keys(props.item[frequencyType]).length >= 2) {
    let amount = props.item[frequencyType].amount !== undefined && props.item[frequencyType].amount !== null
      ? props.item[frequencyType].amount
      : ''
    let exposures = props.item[frequencyType].exposures !== undefined && props.item[frequencyType].exposures !== null
      ? props.item[frequencyType].exposures
      : 'NC'
    let period = props.item[frequencyType].period !== undefined && props.item[frequencyType].period !== null
      ? props.item[frequencyType].period
      : 'NC'
    maxFreqString = `${exposures} imp per ${amount} ${period}${period !== 'lifetime' ? '(s)' : ''}`
  }
  return maxFreqString
}

const unknownDemographicConf = {
  label: 'overwrite unknown demographic',
  value: 'overwrite_unknown_demographic',
  isOrange: function (props) {
    return props.item.overwrite_unknown_demographic === 'only_if'
  },
  trueValue: 'always'
}

export default {
  name: 'DSPOverwriting',
  props: ['props'],
  components: {
    LabelLine
  },
  data: function () {
    return {
      /**
       * advanced : value are not displayed is they are equal to the default value.
       * reverse boolean : In contrary to other item, reverse boolean are ON when false, and OFF when true
       */
      overwriteData: {
        [this.$APPNEXUS]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'overwrite timeparting',
            value: 'remove_time_parting'
          },
          {
            label: 'overwrite budget <span class="overwrite-info-gl-cp">global</span>',
            value: 'remove_daily_budget_global',
            displayRules: (props) => {
              return props.remove_daily_budget_global === true
            }
          },
          {
            label: 'overwrite budget <span class="overwrite-info-gl-cp">campaigns</span>',
            value: 'remove_daily_budget_campaigns_only',
            displayRules: (props) => {
              return props.remove_daily_budget_campaigns_only === true
            }
          },
          {
            label: 'overwrite budget',
            value: '',
            displayRules: (props) => {
              return (props.remove_daily_budget_campaigns_only === undefined || props.remove_daily_budget_campaigns_only === false) &&
                (props.remove_daily_budget_global === undefined || props.remove_daily_budget_global === false)
            }
          },
          {
            label: 'overwrite creative selection',
            value: 'overwrite_creative_selection'
          },
          {
            label: 'overwrite trusted inventories',
            value: 'keep_trusted_inventory',
            isAdvanced: true,
            reverseBoolean: true
          },
          {
            label: 'overwrite fold position',
            value: 'remove_fold_position',
            isAdvanced: true
          },
          {
            label: 'overwrite viewability',
            value: 'remove_min_viz',
            isAdvanced: true
          },
          {
            label: 'overwrite min completion',
            value: 'remove_min_crate',
            isAdvanced: true
          },
          {
            label: 'Force LI daily pacing ASAP',
            value: 'force_li_daily_pacing_asap',
            isAdvanced: true
          }
        ],
        [this.$DBM]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'overwrite timeparting',
            value: 'keep_timeparting',
            reverseBoolean: true
          },
          {
            label: 'overwrite viewability',
            value: 'keep_min_viz',
            reverseBoolean: true
          },
          {
            label: 'force pacing asap',
            value: 'force_pacing_asap_li'
          },
          {
            label: 'overwrite daily budget li',
            value: 'overwrite_daily_budget_li'
          },
          {
            label: 'remove fold position',
            value: 'remove_fold_position'
          },
          {
            label: 'overwrite creative selection',
            value: 'overwrite_creative_selection'
          },
          {
            label: 'automatically remove deprecated url',
            value: 'automatically_remove_deprecated_url'
          },
          unknownDemographicConf
        ],
        [this.$MEDIAMATH]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'remove fold position',
            value: 'remove_fold_position'
          },
          {
            label: 'remove timeparting',
            value: 'remove_timeparting'
          },
          {
            label: 'remove budget strat money',
            value: 'remove_budget_strat_money'
          },
          {
            label: 'remove budget strat imp',
            value: 'remove_budget_strat_imp'
          },
          {
            label: 'force pacing asap money',
            value: 'force_pacing_asap_money'
          },
          {
            label: 'force pacing asap imp',
            value: 'force_pacing_asap_imp'
          }
        ],
        [this.$THETRADEDESK]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'remove timeparting',
            value: 'remove_timeparting'
          },
          {
            label: 'remove fold position',
            value: 'remove_fold_position'
          },
          {
            label: 'overwrite daily budget money adgroup',
            value: 'overwrite_daily_budget_money_adgroup'
          },
          {
            label: 'overwrite daily budget impressions adgroup',
            value: 'overwrite_daily_budget_impressions_adgroup'
          },
          {
            label: 'overwrite viewability',
            value: 'overwrite_viewability'
          }
        ],
        [this.$YOUTUBE]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'overwrite frequency target',
            value: 'overwrite_frequency_target',
            tooltip: tooltipFunctionTarget,
            isOrange: tooltipFunctionTarget
          },
          {
            label: 'overwrite li budget',
            value: 'overwrite_li_budget'
          },
          {
            label: 'automatically remove deprecated url',
            value: 'automatically_remove_deprecated_url'
          },
          {
            label: 'overwrite bid adjustments on device',
            value: 'overwrite_bid_adjustments_on_device'
          },
          {
            label: 'remove time parting',
            value: 'remove_time_parting'
          },
          unknownDemographicConf,
          {
            label: 'Allow switch IO pacing to ASAP',
            value: 'allow_switch_io_pacing'
          }
        ],
        [this.$BEESWAX]: [
          {
            label: 'overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          }
        ],
        [this.$FACEBOOK]: [
          {
            label: 'use adset budgets',
            value: 'use_adset_budgets',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          }
        ],
        [this.$META]: [
          {
            label: 'Overwrite campaign budget',
            value: 'overwrite_campaign_budget',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          }
        ]
      }
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    isOn (item) {
      if (item.trueValue) {
        return item.value === item.trueValue
      }
      return item.value
    }
  },
  computed: {
    overwrited: function () {
      let dsp = this.$route.params.dsp

      if (Object.keys(this.overwriteData).indexOf(dsp) === -1) {
        console.warn(`The dsp ${dsp} is not configured in overwriteData. No overwrite data will be displayed.`)
        return {}
      }

      let filteredOverwrited = this.overwriteData[dsp].filter(item => {
        let defaultItem = this.$store.getters.getDefaultItem
        let toCheck = this.props.item[item.value] === undefined || this.props.item[item.value] === null ? false : this.props.item[item.value]
        return (!item.isAdvanced || (item.isAdvanced && toCheck !== defaultItem[item.value])) &&
          (item.displayRules === undefined || item.displayRules(this.props.item))
      })

      return filteredOverwrited.map(item => {
        let tooltip = false
        let isOrange = false
        if (typeof item.tooltip === 'function') {
          tooltip = item.tooltip(this.props)
        }
        if (typeof item.isOrange === 'function') {
          isOrange = item.isOrange(this.props)
        }

        const trueValue = item.trueValue ? item.trueValue : null

        if (this.props.item[item.value] === undefined || this.props.item[item.value] === null) {
          return {
            label: item.label,
            value: !!item.reverseBoolean,
            isAdvanced: item.isAdvanced,
            tooltip: tooltip,
            isOrange: isOrange,
            trueValue: trueValue
          }
        }
        return {
          label: item.label,
          value: item.reverseBoolean ? !this.props.item[item.value] : this.props.item[item.value],
          isAdvanced: item.isAdvanced,
          tooltip: tooltip,
          isOrange: isOrange,
          trueValue: trueValue
        }
      })
    }
  },
  watch: {

  }
}
</script>

<style>
.overwrite-info-gl-cp {
  color: crimson;
}
</style>

<template>
    <tr
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
    class="selenium-expand-line"
    >
        <ExpandFragment
          :props="props"
          :selected="selected"
          :useCheckBox="false"
          :isHover="isHover"
          class="expand-box"
        >
        </ExpandFragment>
        <InfoInstructionsFragments
          :props="props"
          class="expand-table-td"
        >
        </InfoInstructionsFragments>
        <GroupNameFragment
          :props="props"
          class="expand-table-td"
        >
        </GroupNameFragment>
        <OptimizeFragment
          :props="props"
          :config="config.optimize[dsp]"
          class="expand-table-td"
        >
        </OptimizeFragment>

        <ObjectivesFragment
          :props="props"
          class="expand-table-td"
        >
        </ObjectivesFragment>
        <ObjectiveStrategy
          :props="props"
          class="expand-table-td"
        >
        </ObjectiveStrategy>

        <td class="delete-div justify-center layout px-0 fix-width">
        <v-row class="align-center justify-center column fill-height ma-0">
          <div v-if="isHover">
            <div v-show="$store.getters.isInstructionStratEnabled">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-ripple="{ class: `primary--text`, center: true }"
                    v-on="on"
                    small
                    class="mr-2 mt-2 elevation-3 selenium-open-strat-button"
                    @click="askForOpenInstruStrat(props.item)"
                  >
                    remove_red_eye
                  </v-icon>
                </template>
                <span :id="'open_' + props.item.id">Open instruction strat info</span>
              </v-tooltip>
            </div>
            <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-ripple="{ class: `primary--text`, center: true }"
                v-on="on"
                small
                class="mr-2 mt-2 elevation-3 selenium-delete-button"
                @click="askForDeleteItem(props.item)"
                :disabled="disabledDelete"
              >
                delete
              </v-icon>
            </template>
            <span :id="'delete_' + props.item.id">Delete instruction</span>
          </v-tooltip>
          </div>
        </v-row>
        </td>
    </tr>
</template>

<script>
import {
  ExpandFragment,
  GroupNameFragment,
  InfoInstructionsFragments,
  ObjectivesFragment,
  ObjectiveStrategy,
  OptimizeFragment
} from './Fragments'
import { getFullInsertionOrderOfInstruction } from '../../../../utils/instructionsUtils'

export default {
  name: 'ExpandDSP',
  props: {
    selected: {
      type: Object
    },
    props: {
      type: Object
    },
    dsp: {
      type: String
    },
    disabledDelete: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InfoInstructionsFragments,
    GroupNameFragment,
    ExpandFragment,
    ObjectivesFragment,
    ObjectiveStrategy,
    OptimizeFragment
  },
  data: function () {
    return {
      isHover: false,
      toDisplay: {
        client: true,
        advertiser: true,
        io: false
      },
      config: {
        optimize: {
          [this.$APPNEXUS]: [
            {
              label: 'LI ID',
              value: this.$OPTIMIZE_LINEITEMS
            },
            {
              label: 'CA ID',
              value: this.$OPTIMIZE_CAMPAIGNS,
              isAdvanced: true
            }
          ],
          [this.$MEDIAMATH]: [
            {
              label: 'Strat',
              value: this.$OPTIMIZE_STRATEGIES
            },
            {
              label: 'CA ID',
              value: this.$OPTIMIZE_CAMPAIGNS
            }
          ],
          [this.$DBM]: [
            {
              label: 'LI ID',
              value: this.$OPTIMIZE_LINEITEMS
            }
          ],
          [this.$THETRADEDESK]: [
            {
              label: 'AdGroup',
              value: this.$OPTIMIZE_ADGROUPS
            }
          ],
          [this.$YOUTUBE]: [
            {
              label: 'LI ID',
              value: this.$OPTIMIZE_LINEITEMS
            }
          ],
          [this.$BEESWAX]: [
            {
              label: 'LI ID',
              value: this.$OPTIMIZE_LINEITEMS
            }
          ],
          [this.$FACEBOOK]: [
            {
              label: 'AD ID',
              value: this.$OPTIMIZE_ADSETS
            }
          ],
          [this.$KAYZEN]: [
            {
              label: 'CA ID',
              value: this.$OPTIMIZE_CAMPAIGNS
            }
          ]
        }
      }
    }
  },
  created: function () {},
  mounted: function () {
  },
  methods: {
    askForEditItem (item) {
      this.$emit('edit-item', item)
    },
    askForDeleteItem (item) {
      this.$emit('delete-item', item)
    },
    /**
     * @param item : {Object}
     */
    askForOpenInstruStrat (item) {
      let ioId = getFullInsertionOrderOfInstruction(item, this.$route.params.dsp)
      this.$emit('open-strat', ioId, item.id)
    },
    // if value contain a dot like 'objective.max_CPM', return prop['objective']['max_CPM']
    // otherwise (like 'is_active' ) return prop['is_active']
    getPropsValue (prop, value) {
      let fields = value.split('.')
      if (fields.length === 1) {
        return prop[fields[0]]
      }

      return prop[fields[0]][fields[1]]
    }
  },
  computed: {
    removeOnlyHeader () {
      let headers = this.$dspConfig[this.dsp].headers
      let headerFilter = function (row) {
        return typeof row.onlyHeader === 'undefined' || row.onlyHeader === false
      }
      return headers.filter(row => headerFilter(row))
    }
  },
  watch: {

  }
}
</script>

<style>
.fix-width {
  width: 4em;
}
</style>

<template>
  <div class="conversion-funnel-component">
    <div>
      <slot name="label">
        <LabelLine :mini="true" :label="label"></LabelLine>
      </slot>
    </div>

    <div v-for="(index) in Object.keys(conversionFunnel).slice(0,nbDisplayedFunnelsComputed)" :key="index">
      <LabelLine v-if="!noIndex" :mini="true" :label="index + ':'"></LabelLine>
      <span
        v-for="pixel in conversionFunnel[index].slice(0,nbDisplayedPixels ? nbDisplayedPixels : Object.keys(conversionFunnel[index]).length)"
        :key="pixel.id + '_' + Math.random() + '_' + index"
      >
          <v-chip color="#3486d7" small class="mini-chip pl-0 ma-1">
            <v-avatar color="#2E6DB3" class="avatar-mini-chip">
              <span style="color: white">{{pixel.origin === '3rd_party' ? '3rd' : 'DSP'}}</span>
            </v-avatar>

            ({{pixel.type}})

            {{pixel.id}}
          </v-chip>
      </span>
    </div>
  </div>
</template>

<script>
import LabelLine from '../TableComponents/Tools/LabelLine'
export default {
  name: 'ConversionFunnelComponent',
  components: {
    LabelLine
  },
  props: {
    conversionFunnel: {
      type: Object,
      default: () => {}
    },
    nbDisplayedFunnels: {
      type: Number
    },
    nbDisplayedPixels: {
      type: Number
    },
    label: {
      type: String,
      default: 'Funnel : '
    },
    noIndex: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    nbDisplayedFunnelsComputed () {
      return this.nbDisplayedFunnels ? this.nbDisplayedFunnels : Object.keys(this.conversionFunnel).length
    }
  }
}
</script>

<style scoped>
  .avatar-mini-chip {
    margin-right: 0 !important;
    font-size: 7px;
  }

</style>

<style>
  .conversion-funnel-component span.v-chip__content {
    color: white;
    font-size: 7px;
    height: 23px;
    width: 11em;
    text-align: center;
  }
</style>

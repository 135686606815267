<template>
  <div id="instru-table-main-container" :class="`main-table-${dsp}`">
    <v-toolbar flat color="white">
      <v-row wrap no-gutters>
        <v-card-title>
          <v-text-field
            v-model.trim="searchBufferize"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            class="pa-1"
            id="search-bar"
            @input="onUpdateSearchBufferize"
          >
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="showComplexQuery = !showComplexQuery">
                <v-icon>{{ showComplexQuery ? 'remove_circle_outline' : 'control_point' }}</v-icon>
              </v-btn>
            </template>
            <span>
            Open complex search bar
          </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="refreshData()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>
            Refresh data
          </span>
          </v-tooltip>

        </v-card-title>

        <v-spacer></v-spacer>

        <v-row
          class="img-data-table-card ma-auto"
          align-center
          row
          spacer
        >
          <v-col cols="4">
            <v-avatar class="img-data-table" :size="40" :tile="[$YOUTUBE, $META].includes(dsp)">
              <img
                :src="require('../../assets/' + dsp + '.png')"
              />
            </v-avatar>
          </v-col>
          <v-col cols="4" class="align-content-center">
            <span>{{ dsp }}</span>
          </v-col>
        </v-row>

        <!-- New btn -->

        <v-tooltip bottom v-if="isDspBeta">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="openNewFormBetaDsp()" color="primary" dark class="ma-2">New</v-btn>
          </template>
          <span>Create a new item</span>
        </v-tooltip>

        <!-- INSIGHT BUTTON -->
        <v-dialog eager v-model="dialogInsight" max-width="500px">
          <DecksDialog
            :value="dialogInsight"
            :selected="selectedIos"
            :dsp="dsp"
            v-on:close-insight="closeInsight()"
            v-on:success="success"
            v-on:warning="warning"
            v-on:error="error"
            :key="dialogInsight"
          >
          </DecksDialog>
        </v-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="openInsight()" slot="activator" color="primary" dark class="ma-2">Decks</v-btn>
          </template>
          <span>
            {{tooltipMessageAbTestDeck()}}
          </span>
        </v-tooltip>

        <!-- ACTION BUTTON -->
        <v-dialog eager v-model="dialogAction" max-width="500px">
          <ActionFormV2
            class="py-2"
            :selected="selected"
            :selectedIos="selectedIos"
            :numberOfSelectedItem="numberOfSelectedItem"
            :action="action"
            v-on:close-action="closeAction"
            v-on:action-method="actionMethod"
            :key="dialogAction"
            v-on:update-overwrites="refreshData"
          >
          </ActionFormV2>
        </v-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="openAction()" color="primary" dark class="ma-2">Action</v-btn>
          </template>
          <span>Multi line action box</span>
        </v-tooltip>

        <!-- FORM -->
        <v-dialog
          eager
          v-model="dialog"
          max-width="1350px"
          transition="slide-x-transition"
          origin="center center"
          :persistent="true"
        >
          <IOForm
            v-if="dsp && editedItem"
            :formTitle="formTitle"
            :editedItem="editedItem"
            :editedInstructions="editedInstructionsIO"
            v-on:close="close"
            v-on:save="save"
            v-on:ask-emit-query-string="$emit('query-string')"
            v-on:open-strat="openInstruStratDialog"
            :dataFormGroupKey="dataFormGroupKey"
            :key="keyIoForm"
            :isEditForm="isEditForm"
          >
          </IOForm>
        </v-dialog>

        <!-- Identity io form for beta dsp -->
        <v-dialog
          eager
          width="500px"
          v-model="dialogIOIdentityForm"
          transition="slide-x-transition"
        >
          <IOIdentityForm
            v-if="dsp && editedItem"
            :dsp="dsp"
            v-on:close="closeIdentityForm"
            v-on:next="nextNewFormBetaDsp"
            :key="dialogIOIdentityForm"
          >
          </IOIdentityForm>
        </v-dialog>

        <!-- BULK ADD FORM -->
        <SimpleDialog
          ref="bulk-add-dialog"
          maxWidth="1000px"
          transition="slide-x-transition"
          origin="center center"
          :persistent="true"
          :model="bulkAddDialogForm"
          :headerContent="`bulk add ${bulkAddId} - briefs listing`"
          v-on:closeSimpleDialog="closeNewBulkAddForm"
        >
          <template v-slot:bodyContent>
            <BulkAddBriefListingComponent
              v-if="bulkAddId != null"
              :bulkAddId="bulkAddId"
              :dataFormGroupKey="dataFormGroupKey"
              ref="briefsList"
            />
          </template>
        </SimpleDialog>

        <OutcomesDialog
          :insertion-order-id="insertionOrderIdStrat"
          :instruction-id-props="instructionIdStrat"
          v-model="dialogInstruStrat"
          :key="dialogInstruStrat"
        >
        </OutcomesDialog>

        <v-dialog
          eager
          :value="$store.getters.getEditUpdateStatus"
          origin="center center"
          class="container-edit-progress"
          max-width="300px"
        >
          <WaitDialog>

          </WaitDialog>
        </v-dialog>

        <RemoveScibidsDialog
          :ioID="removeScibidsData.ioID"
          :memberExternalID="removeScibidsData.memberExtID"
          :dsp="removeScibidsData.dsp"
          :removeScibidsDialog="removeScibidsDialog"
          :removeScibidsModel.sync="removeScibidsData.removeScibidsModel"
          v-on:close="closeRemoveScibidsDialog"
        />

        <!-- EXPANDS ALL ROWS BUTTON -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" dark @click="expandsAllRows()" class="ma-2">
              <v-icon> {{ allRowExpanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ allRowExpanded ? 'Decrease all rows' : 'Extends all rows' }}</span>
        </v-tooltip>

        <v-btn v-if="keepOtherRowEnabled" color="primary" dark @click="expand = !expand">
          {{ expand ? 'Close' : 'Keep' }} other rows
        </v-btn>
      </v-row>
    </v-toolbar>

    <v-slide-y-transition>
      <ComplexSearchBar
        v-show="showComplexQuery"
        v-on:search-complex="emitSearchComplex"
        :baseline-search.sync="baselineSearch"
      >
      </ComplexSearchBar>
    </v-slide-y-transition>

    <v-data-table
      :headers="getIOHeader"
      :headers-length="getIOHeaderLength"
      :items="ioList"
      :expanded.sync="expanded"
      item-key="id"
      :loading="loading"
      :options.sync="options"
      ref="dataTableRefIO"
      hide-default-header
      :selected="selected"
      :server-items-length="totalItems"
      @update:options="onUpdatePagination"
      class="instruction-data-table std-table"
      :footer-props="{
        itemsPerPageOptions: arrayPagination
      }"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th class="selected-header px-0">
            </th>
            <th
              v-for="header in headers"
              :key="header.text"
              :class="[header.class !== undefined ? header.class : '','column sortable', options.descending ? 'desc' : 'asc', header.value === options.sortBy ? 'active' : '', 'px-0 text-center']"
              @click="header.sortable ? changeSort(header.value) : ''"
            >
              <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
            <th class="edition-header-th px-0">
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:progress>
        <v-progress-linear color="blue" indeterminate/>
      </template>
      <template v-slot:item="props">
        <LineIO
          :props="props"
          :selected="selected"
          :dsp="dsp"
          v-on:select-instructions="selectRowsInstructions"
          v-on:select-item-and-kpi="selectRowsKpi"
          v-on:edit-item="editItem"
          v-on:open-strat="openInstruStratDialog"
          v-on:removeScibids="openRemoveScibidsDialog"
          :dataIsLoading="loading"
        >
        </LineIO>
      </template>

      <template v-slot:no-data>
        <v-alert type="info" icon="info">
          No data was found for your search
        </v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert type="info" icon="info">
          No data was found for your search
        </v-alert>
      </template>

      <template v-slot:expanded-item="props">
        <td colspan="9" style="background-color: #f3f3f3">
          <v-row wrap class="expanded-table-container justify-center">
            <v-col cols="10">
              <v-data-table
                :items="instructions[props.item.io]"
                :keys="props.item.io"
                item-key="id"
                :ref="'dataTableRef_'+ props.item.io"
                :selected="selected"
                :hide-default-header="true"
                :hide-default-footer="true"
                :footer-props="{
                  itemsPerPageOptions: [100]
                }"
              >
                <template v-slot:item="subProps">

                  <ExpandDSP
                    :selected="selected"
                    :props="subProps"
                    :dsp="dsp"
                    v-on:edit-item="editItem"
                    v-on:delete-item="deleteItem"
                    v-on:open-strat="openInstruStratDialog"
                    :id="subProps.item.id"
                    :disabled-delete="isDisabledDelete(props.item.io)"
                  >
                  </ExpandDSP>

                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:pageText="props">
        {{ props.pageStart }} - {{ displayedPageStop }} of {{ props.itemsLength }}
        {{ displayedPageStop === props.itemsLength ? '' : '+' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ExpandDSP, LineIO } from './index' // always called, don't need Lazy Loading
import { mapMutations } from 'vuex'
import SimpleDialog from '../TableComponents/Form/FormFragments/SimpleDialog.vue'
import BulkAddBriefListingComponent from '../TableComponents/Form/BulkAddBriefListingComponent.vue'
import { isBetaDsp } from '../../../types/instruction_type_helper'
import RemoveScibidsDialog from '../TableComponents/Form/FormFragments/RemoveScibidsDialog.vue'
import ioFormMixin from '@/mixins/ioFormMixin'

export default {
  name: 'Table',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  mixins: [ ioFormMixin ],
  components: {
    DecksDialog: () => import('./Form/DecksDialog.vue'),
    IOForm: () => import('./Form/IOForm'),
    ActionFormV2: () => import('./Form/ActionFormV2'),
    ComplexSearchBar: () => import('./Form/ComplexSearchBar'),
    LineIO,
    ExpandDSP,
    WaitDialog: () => import('../Common/WaitDialog.vue'),
    OutcomesDialog: () => import('./Outcomes/OutcomesDialog.vue'),
    SimpleDialog,
    BulkAddBriefListingComponent,
    IOIdentityForm: () => import('./Form/IOIdentityForm'),
    RemoveScibidsDialog
  },
  data () {
    return {
      searchBufferize: '',
      timeoutHandle: null,
      timeoutHandleGroupName: null,
      // data for update box
      dialogAction: false,
      dialogInsight: false,
      dialogIOIdentityForm: false,
      editedIOIndex: -1,
      /**
       * @type {Instruction[]}
       */
      // end data update box
      expanded: [],
      loading: true,
      action: {
        retry: 1,
        fast_retry: 1,
        is_active: 1,
        reactivate_otto: 1
      },
      // TOGGLE ALL VALUES
      selected: {},
      /**
       * @type {SelectedIo}
       */
      selectedIos: {},
      allRowExpanded: false,
      numberOfSelectedItem: null,
      // COMPLEX QUERY FIELD VALUES
      showComplexQuery: false,
      // enabled / disabled features
      keepOtherRowEnabled: false,
      //
      arrayPagination: [20, 30, 50],
      // if you want than after each search all expandedare reset
      resetAllExpandedAfterSearch: true,
      // if you want allare auto expanded
      autoExpand: false,
      isEditForm: true,
      bulkAddDialogForm: false,
      bulkAddId: null,
      removeScibidsData: {},
      removeScibidsDialog: false
    }
  },
  methods: {
    ...mapMutations(['setErrorMessage', 'editStartUpdate', 'editEndUpdate']),
    /**
     * Call the api for the io,
     * update the instruction in the collection
     * and open the IoForm
     * @param item {import('../../../types/instruction_type').IoBrief}
     */
    async editItem (item) {
      // 1. call api for each id
      this.editStartUpdate()
      this.editedIOIndex = item.io
      let updatedInstruction = []

      const data = {
        dsp: this.dsp,
        [this.getIOField()]: item.io
      }

      const response = await this.$apiCaller.getInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
        this.editEndUpdate()
        return
      }

      updatedInstruction = response.data

      // replace instruction per her updated version
      // TODO need better solution (bad practice to call parent data). When call in instruction mixin will be included in ScibidsApicaller
      // TODO this value will be in the store
      // 2. replace the instruction per the instruction received
      this.$set(this.$parent.instructionSortedByIo, this.editedIOIndex, updatedInstruction)

      // 3. open the form
      this.$nextTick(() => {
        // get updated insertion_order
        let updatedItem = this.ioList.filter((io) => {
          return io.io === item.io
        })
        // update id_list
        updatedItem[0].id_list = updatedInstruction.map(item => item.id)
        const ioBrief = updatedItem[0]
        this.openEditForm(ioBrief, updatedInstruction)
        this.editEndUpdate()
      })
    },
    /**
     *
     * @param ioBrief {import('../../../types/instruction_type').IoBrief}
     * @param updatedInstructions {Instruction[]}
     */
    openEditForm (ioBrief, updatedInstructions) {
      this.$authModule.checkIfRefreshIsNeeded()
      this.$store.commit('setFormStatus', this.$EDIT)
      this.editedInstructionsIO = this.$commonUtils.deepCopy(updatedInstructions)
      this.editedItem = this.$commonUtils.deepCopy(ioBrief)
      this.isEditForm = true
      this.dialog = true
    },
    /**
     * Allow to open the IoForm with just a insertionOrderId.
     * Will request the instructions from the api,
     * create the IoBrief object and open the form.
     * @param insertionOrderId
     * @return {Promise<void>}
     */
    async editItemWithIoId (insertionOrderId) {
      const data = {
        dsp: this.dsp,
        [this.getIOField()]: insertionOrderId
      }

      const response = await this.$apiCaller.getInstructions(data, false)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
        this.editEndUpdate()
        return
      }

      /**
       * @type {Instruction[]}
       */
      const updatedInstructions = response.data
      /**
       * @type {CollectionInstruction<Instruction>}
       */
      const collectionOfInstructions = { [insertionOrderId]: updatedInstructions }
      const results = this.insertionOrderProcessor.mapListIO(collectionOfInstructions, this.$dspConfig[this.dsp])

      if (!results || !results.length) {
        this.$store.commit('setErrorMessage', 'Error when processing instructions.')
        this.editEndUpdate()
        return
      }

      const ioBrief = results[0]

      this.$nextTick(() => {
        this.openEditForm(ioBrief, updatedInstructions)
        this.editEndUpdate()
      })
    },
    /**
     * Delete the item on front end, and emit a event for delete the element
     * @param item {Instruction}
     */
    deleteItem (item) {
      let ioField = this.getIOField()
      // don't need to register the information into this.editedIO here
      let editedIO = item[ioField]
      const index = this.instructions[editedIO].indexOf(item)
      if (confirm('Are you sure you want to delete this item?')) {
        let instructionIO = this.instructions[editedIO]
        instructionIO.splice(index, 1)
        this.$set(this.instructions, editedIO, instructionIO)
        this.$emit('delete-element', item)
      } else {
        console.warn('delete aborted')
      }
    },
    closeNewBulkAddForm () {
      this.bulkAddDialogForm = false
      this.$refs.briefsList.clearInterval()
    },
    /**
     * called when user click on the save button of the IOForm
     * emit the appropriate call request to the parent element
     * in function of the call action
     */
    save (acknowledgments = null, baseline = null) {
      if (this.formIsEdit()) {
        // for editing
        this.editMultiProcess(acknowledgments, baseline)
      } else {
        // for new item
        this.createMultiProcess(acknowledgments, baseline)
      }
      this.close()
    }, // end method update box
    /**
     * process called when form is in edit
     * emit a event 'delete-and-post-raw'
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @param baseline {Baseline}
     */
    editMultiProcess (acknowledgments = null, baseline = null) {
      const result = this.prepareEditMultiProcess()
      this.$emit('delete-and-post-raw', [
        ...result.editedInstru,
        ...result.newInstru
      ], acknowledgments, result.toDeleteInstruId, baseline)
    },
    createMultiProcess (acknowledgments = null, baseline = null) {
      this.$removeEmptyFieldFromObject(this.editedItem)

      for (let i in this.editedInstructionsIO) {
        if (!this.editedInstructionsIO.hasOwnProperty(i)) {
          continue
        }

        this.$removeEmptyFieldFromObject(this.editedInstructionsIO[i])

        let ioBrief = this.editedItem

        if (this.dsp === this.$BEESWAX) {
          ioBrief = this.$commonUtils.deepCopy(this.editedItem)
          ioBrief.io = ioBrief.io.split('_')[1]
        }

        this.editedInstructionsIO[i] = this.insertionOrderProcessor.fillInstructionsWithIOBrief(
          this.editedInstructionsIO[i],
          ioBrief,
          this.$dspConfig[this.dsp]
        )

        if (this.editedInstructionsIO[i].id) {
          delete this.editedInstructionsIO[i].id
        }
      }
      this.$emit('delete-and-post-raw', this.editedInstructionsIO, acknowledgments, null, baseline)
    },
    /**
     * process for the action form
     * emit an event : 'buckets-update'
     */
    actionMethod () {
      let bucketsId = []

      for (let id in this.selected) {
        if (this.selected[id] === true) {
          bucketsId.push(id)
        }
      }
      let bucketsIdImplode = bucketsId.join(',')

      let action = this.createActionObject()
      this.$emit('buckets-update', bucketsIdImplode, action)
      // set the state of the app
      this.$store.commit('setAppState', this.$HAVE_JUST_DONE_BULK)
      this.closeAction()
      // reset value with default value
      this.resetActionValues()
    },
    createActionObject () {
      let actionObject = {}
      for (let key in this.action) {
        if (this.action[key] === 1) {
          continue
        }

        actionObject[key] = this.action[key] !== 0
      }

      return actionObject
    },
    closeAction () {
      this.dialogAction = false
      this.resetActionData()
    },
    resetActionData () {
      this.action = {
        retry: 1,
        fast_retry: 1,
        is_active: 1,
        reactivate_otto: 1
      }
    },
    resetActionValues () {
      // reset value with default value
      this.resetActionData()
      this.selected = {}
    },
    resetAllExpandedValues () {
      this.allRowExpanded = false
    },
    resetGeneralValues () {
      this.search = ''
      this.searchBufferize = ''
      this.selected = {}
      this.selectedIos = {}
      this.showComplexQuery = false
      this.resetPagination()
      this.$emit('query-string')
    },
    resetPagination () {
      this.options.page = 1
      this.options.itemsPerPage = 20
    },
    resetSelectAllValues () {
      this.selected = {}
      this.numberOfSelectedItem = null
    },
    /**
     * @param column : {String} the column to sortBy
     */
    changeSort (column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending
      } else {
        this.options.sortBy = column
        this.options.descending = false
      }
    },
    refreshData () {
      console.log('next page ?')
      this.$emit('refresh-data', this.search)
    },
    /**
     * @returns {String} : the client field in function of the dsp member_id | partner_id | organization_id
     */
    getClientValue () {
      return this.$getClientValue(this.dsp)
    },
    /**
     * @returns {String} : the insertion order field in function of the dsp
     */
    getIOField () {
      return this.$getIoField(this.dsp)
    },
    /**
     * @returns {String} : the advertiser field in function of the dsp
     */
    getAdvertiserKey () {
      return this.dsp !== this.$FACEBOOK ? 'advertiser_id' : 'account_id'
    },
    expandsAllRows () {
      console.log('expand all rows')
      console.log(this.allRowExpanded)
      for (let i = 0; i < this.ioList.length; i++) {
        const item = (!this.allRowExpanded) ? this.ioList[i] : null
        this.$set(this.$refs.dataTableRefIO.expanded, i, item)
      }
      this.allRowExpanded = !this.allRowExpanded
    },
    selectRowsInstructions (ids = [], state) {
      for (let key in ids) {
        this.$set(this.selected, ids[key], state)
      }
    },
    selectRowsKpi (id, instruction, state) {
      this.$set(this.selectedIos, id, { instruction, state })
    },
    emitSearchComplex (searchObject) {
      this.clearSearch()
      this.resetPagination()
      this.$emit('search-complex', searchObject)
      this.resetSelectAllValues()
    },
    /**
     * @returns {boolean}
     */
    formIsNew () {
      return this.$store.getters.getFormStatus === this.$NEW
    },
    /**
     * look if a search query is defined in $route.query or
     * in the store.
     * If found, the search query is put in the search bar
     */
    getCurrentSearchFromQueryOrStore () {
      const currentSearch = this.$store.getters.getCurrentSearch
      const searchFromStore = currentSearch.search
      if (this.$route.query.q_search !== undefined) {
        this.searchBufferize = this.$route.query.q_search
      } else if (searchFromStore !== undefined && searchFromStore !== '') {
        this.searchBufferize = searchFromStore
      }
    },
    async getCurrentOpenDialog () {
      if (!this.$route.params.openDialog) {
        return
      }
      const openDialog = this.$route.params.openDialog
      const dialogId = this.$route.params.dialogId
      if (openDialog === 'outcomes' && dialogId) {
        this.openInstruStratDialog(this.$route.params.dialogId)
      } else if (openDialog === 'ioForm' && dialogId) {
        console.warn('Open io form (not implemented)')
        await this.editItemWithIoId(dialogId)
      }
    },
    openNewFormBetaDsp () {
      this.dialogIOIdentityForm = true
    },
    nextNewFormBetaDsp (identity) {
      this.$store.commit('setFormStatus', this.$NEW)
      this.setEditedItemToDefaultValue()
      this.editedItem.ad_account_id = identity.advertiserExternalId
      this.editedItem.business_manager_id = identity.memberExternalId
      this.editedItem.campaign_id = identity.insertionOrderId
      this.editedItem.io = String(identity.insertionOrderId)
      this.dialogIOIdentityForm = false
      this.isEditForm = false
      this.dialog = true
    },
    closeIdentityForm () {
      this.dialogIOIdentityForm = false
    },
    openNewForm () {
      this.$store.commit('setFormStatus', this.$NEW)
      this.setEditedItemToDefaultValue()
      this.isEditForm = false
      this.dialog = true
    },
    openNewBulkAddForm () {
      this.$store.commit('setFormStatus', this.$NEW)
      this.setEditedItemToDefaultValue()
      this.bulkAddDialogForm = true
    },
    openAction () {
      this.dialogAction = true
    },
    /**
     * @param ioId {string}
     */
    isDisabledDelete (ioId) {
      return this.instructions[ioId] && this.instructions[ioId].length <= 1
    },
    onUpdatePagination () {
      this.refreshData()
    },
    clearSearch () {
      this.search = ''
      this.searchBufferize = ''
    },
    onUpdateSearchBufferize () {
      if (this.timeoutHandle !== null) {
        clearTimeout(this.timeoutHandle)
      }

      this.resetPagination()

      if (this.resetAllExpandedAfterSearch) {
        this.resetAllExpandedValues()
      }
      this.timeoutHandle = window.setTimeout(function () {
        this.search = this.searchBufferize
        this.$nextTick(() => {
          this.$emit('refresh-data')
          this.$emit('query-string')
        })
      }.bind(this), 400)
    },

    openInsight () {
      this.dialogInsight = true
    },

    closeInsight () {
      this.dialogInsight = false
    },

    success (message) {
      this.$emit('ask-snackbar', message, 'success')
    },

    error (message) {
      this.$emit('ask-snackbar', message, 'error')
    },

    warning (message) {
      this.$emit('ask-snackbar', message, 'warning')
    },

    openRemoveScibidsDialog (removeScibidsData) {
      this.removeScibidsData = removeScibidsData
      this.removeScibidsDialog = true
    },
    closeRemoveScibidsDialog () {
      this.removeScibidsDialog = false
      this.removeScibidsData = {}
    },
    tooltipMessageAbTestDeck () {
      const asArray = Object.entries(this.selectedIos)
      const filtered = asArray.filter(([key, value]) => (value.state === true))
      const baseMessage = 'Generate Insights and AB Test Reports.'
      if (filtered.length === 0) {
        return `
            ${baseMessage}
            Please select at least one IO.
        `
      }
      return baseMessage
    }
  },
  created: function () {
    this.setEditedItemToDefaultValue()
    this.getCurrentSearchFromQueryOrStore()
    this.getCurrentOpenDialog()

    if (this.$store.getters.getAppState === this.$HAVE_JUST_DONE_BULK || this.$store.getters.getComplexSearchFromQuery === true) {
      this.showComplexQuery = true
    }
    this.$store.commit('setAppStateToNormal')
  },
  mounted: function () {
    let vm = this

    vm.$nextTick(function () {
      this.instructions = this.data
      try {
        this.ioList = this.insertionOrderProcessor.mapListIO(
          this.instructions,
          this.$dspConfig[this.dsp]
        )
      } catch (TypeError) {
        console.warn('TypeError mapListIO')
      }
    })
  },
  computed: {
    /**
     * @returns {Array}
     */
    refresh: function () {
      return this.data
    },
    /**
     * @returns {string}
     */
    formTitle () {
      return this.getFormStatus + ' item'
    },
    /**
     * @returns {Array}
     */
    getDspHeaders () {
      return this.$dspConfig[this.dsp].headers
    },
    /**
     * @returns {int}
     */
    getDspHeadersLength () {
      return this.$dspConfig[this.dsp].headers.length + 2
    },
    /**
     * @returns {Array}
     */
    getIOHeader () {
      return this.$dspConfig.IOHeader.headers
    },
    /**
     * @returns {int}
     */
    getIOHeaderLength () {
      return this.$dspConfig.IOHeader.headers.length + 3
    },
    /**
     * @returns {string} the actual status of the form [EDIT|NEW]
     */
    getFormStatus () {
      return this.$store.getters.getFormStatus
    },
    keyIoForm () {
      return this.formIsNew() ? `NEW_FORM` : this.editedItem.id
    },
    options: {
      get () {
        return this.$store.getters.getOptionsInstructions
      },
      set (options) {
        this.$store.commit('setOptionsInstructions', options)
      }
    },
    search: {
      get () {
        const currentSearch = this.$store.getters.getCurrentSearch
        return currentSearch.search
      },
      set (search) {
        const currentSearch = this.$store.getters.getCurrentSearch
        currentSearch.search = search
        this.$store.commit('setCurrentSearch', currentSearch)
      }
    },
    baselineSearch: {
      /**
       * @returns {BaselineType | 'N/A'}
       */
      get () {
        const currentSearch = this.$store.getters.getCurrentSearch
        return currentSearch.baseline_type ? currentSearch.baseline_type : 'N/A'
      },
      /**
       * @param baselineSearch {BaselineType | 'N/A'}
       */
      set (baselineSearch) {
        const currentSearch = this.$store.getters.getCurrentSearch
        currentSearch.baseline_type = baselineSearch === 'N/A' ? null : baselineSearch
        this.$store.commit('setCurrentSearch', currentSearch)
      }
    },
    totalItems () {
      if (!this.loading && this.ioList.length < this.options.itemsPerPage) {
        return this.displayedPageStop
      }
      return (this.options.itemsPerPage * (this.options.page + 1)) + 1
    },
    displayedPageStop () {
      const startPage = this.options.itemsPerPage * (this.options.page - 1)
      return this.ioList.length < this.options.itemsPerPage
        ? startPage + this.ioList.length
        : startPage + this.options.itemsPerPage
    },
    isDspBeta () {
      return isBetaDsp(this.dsp)
    }
  },
  watch: {
    refresh: {
      deep: true,
      handler: function () {
        this.instructions = this.data
        this.ioList = this.insertionOrderProcessor.mapListIO(
          this.instructions,
          this.$dspConfig[this.dsp]
        )

        if (this.ioList.length > Math.max(...this.arrayPagination) && this.autoExpand) {
          this.expandsAllRows()
        }
      }
    },
    '$route.params.dsp': function (dsp) {
      this.dsp = dsp
      this.resetGeneralValues()
      this.resetSelectAllValues()
      this.resetAllExpandedValues()
      this.resetActionValues()
      this.close()
    },
    'dialog': {
      immediate: false,
      handler: function (dialog, oldValue) {
        let path
        if (dialog) {
          path = this.$router.resolve({
            params: {
              dsp: this.dsp,
              openDialog: 'ioForm',
              dialogId: this.editedItem.io
            },
            query: this.$route.query
          })
          this.$store.commit('setAppState', this.$FORM_IS_OPEN)

          // In case the user come from the edit url, and the instructions are not in the IoList, the user will be unable
          // to save. For avoid that, we detect when this io is not the instructions collections.
          // If not, we just search the io in searchBufferize
          if (!(this.editedItem.io in this.instructions)) {
            this.searchBufferize = this.editedItem.io
            this.onUpdateSearchBufferize()
          }
        } else {
          path = this.$router.resolve({
            params: {
              dsp: this.dsp,
              openDialog: null,
              dialogId: null
            },
            query: this.$route.query
          })
          this.$store.commit('setAppStateToNormal')
        }
        // check for avoid NavigationDuplicated error
        if (path && path.href !== this.$route.fullPath) {
          this.$router.replace(path.location)
          this.$plausibleHelper.trackPageview()
        }
      }
    },
    '$store.getters.getSteamingProcessInProgress': function (streamingInProgress) {
      this.loading = streamingInProgress
    },
    '$store.getters.getOpenDataForm': {
      immediate: true,
      handler: function (openDataForm) {
        if (openDataForm) {
          console.warn('Form opened with data_form')
          this.dataFormGroupKey = this.$store.getters.getDataFormGroupKey
          let dataFormStatus = this.$store.getters.getDataFormStatus

          if (dataFormStatus === this.$NEW) {
            if (this.dataFormGroupKey.group_key.entity_type === 'BULK_ADD') {
              this.bulkAddId = Number(this.dataFormGroupKey.group_key.entity_id)
              this.openNewBulkAddForm()
            } else {
              this.openNewForm()
            }
          } else {
            this.searchBufferize = this.dataFormGroupKey.group_key.insertion_order_id
            this.onUpdateSearchBufferize()
          }
        }
      }
    },
    dialogInstruStrat: {
      immediate: false,
      handler: function (newValue) {
        if (!newValue) {
          this.insertionOrderIdStrat = null
        }
      }
    }
  }
}
</script>
<style>
.bold-weight {
  font-weight: 600;
}

#instru-table-main-container {
  border-top: 2px solid transparent;
}

#instru-table-main-container .td-data-table-center {
  text-align: center;
}

#instru-table-main-container .td-data-table-left {
  text-align: left;
}

#instru-table-main-container .img-data-table-card {
  font-weight: 500;
}

.expand-arrow {
  cursor: pointer;
}

#instru-table-main-container .info-td {
  text-align: center;
  min-width: 6em;
}

.select-all-rows {
  cursor: pointer;
  color: darkslateblue;
  font-weight: 500;
}

.cursor-default {
  cursor: default;
}

/*
special case facebook, who have long funnel ids
*/
#instru-table-main-container.main-table-facebook .v-chip.mini-chip .v-chip__content,
#instru-table-main-container.main-table-meta .v-chip.mini-chip .v-chip__content,
.pixel-v-menu .v-chip.mini-chip .v-chip__content {
  font-size: 8px;
  height: 15px;
  color: white;
  width: 29em;
  text-align: center;
}

#instru-table-main-container table thead th {
  font-size: 11px !important;
}

/* table.v-table tbody td, table.v-table tbody th {
  height: 93px;
} */

#instru-table-main-container td:not(.v-datatable__expand-row) {
  height: 93px;
}

#instru-table-main-container .theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

#instru-table-main-container tr.v-datatable__expand-row {
  background-color: #f3f3f3 !important;
}

#instru-table-main-container th.selected-header {
  width: 4em !important;
}

#instru-table-main-container th.localization-header-table.column.sortable.asc {
  width: 15em !important;
}

/*
special case facebook, cause long ids
*/
#instru-table-main-container.main-table-facebook th.localization-header-table.column.sortable.asc,
#instru-table-main-container.main-table-meta th.localization-header-table.column.sortable.asc {
  width: 35em !important;
}

#instru-table-main-container th.info-header-table.column.sortable.asc {
  min-width: unset !important;
  width: 4em !important;
}

#instru-table-main-container td.expand-table-td {
  min-width: 17em !important;
}

#instru-table-main-container td.info-td.cursor-default {
  width: 50px !important;
}

#instru-table-main-container td.constraints-to-respect-td {
  max-width: 15em !important;
}

#instru-table-main-container .conversion-funnel-td {
  width: 20em;
}

#instru-table-main-container .v-menu__content.theme--light.menuable__content__active.v-small-dialog__content {
  min-height: 4em;
}

#instru-table-main-container .mini-info-text {
  color: gray;
  font-style: italic;
  font-size: 7px;
  cursor: pointer;
  padding-bottom: 1em;
}

#instru-table-main-container .copy-to-clipboard {
  font-size: 10px;
}

i.v-icon.material-icons.theme--light.red-lens {
  color: indianred !important;
}

i.v-icon.material-icons.theme--light.green-lens {
  color: mediumseagreen !important;
}

button.v-icon.material-icons.theme--light.red-lens {
  color: indianred !important;
}

button.v-icon.material-icons.theme--light.green-lens {
  color: mediumseagreen !important;
}

i.v-icon.material-icons.theme--light.item-is-on {
  opacity: 1;
  color: green;
}

i.v-icon.material-icons.theme--light.item-is-on.flash {
  color: #f2db0e;
}

i.v-icon.material-icons.theme--light.item-is-on.adb {
  color: #ff8700;
}

i.v-icon.material-icons.theme--light.grade {
  font-size: 16px;
  text-shadow: 0px 0px 2px black;
}

i.v-icon.material-icons.theme--light.item-is-on.refresh {
  color: #3486D7;
}

i.v-icon.material-icons.theme--light.item-is-in-between {
  opacity: 1;
  color: orange;
}

i.v-icon.material-icons.theme--light.item-is-off {
  opacity: 0.2;
}

i.v-icon.material-icons.theme--light.item-is-off.more-opacity {
  opacity: 0.6;
}

.reactivate-otto-on {
  opacity: 1;
}

.reactivate-otto-off {
  opacity: 0.2;
}

td.expand-box {
  width: 10px;
}

.instruction-data-table table.v-table thead td:not(:nth-child(1)),
.instruction-data-table table.v-table tbody td:not(:nth-child(1)),
.instruction-data-table table.v-table thead th:not(:nth-child(1)),
.instruction-data-table table.v-table tbody th:not(:nth-child(1)),
.instruction-data-table table.v-table thead td:first-child,
.instruction-data-table table.v-table tbody td:first-child,
.instruction-data-table table.v-table thead th:first-child,
.instruction-data-table table.v-table tbody th:first-child {
  padding: 0 0 !important;
}

.layout.expand-checkbox.align-center.justify-center.row.fill-height {
  padding-left: 6px;
}

th.constraints-header-table.column.sortable.asc {
  width: 15em;
}

th.dsp-header-table.column.sortable.asc {
  width: 30rem;
}

th.algo-header-table.column.sortable.asc {
  width: 20em;
}

.advancedItem {
  color: #3486D7;
}

.theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  border: 1px solid darkgray;
  border-left: none;
  border-right: none;
}

.v-window__container--is-active {
  overflow: hidden;
  height: max-content !important;
}

td.duplicate-rm-box button,
.expand-checkbox .expand-arrow i,
.delete-div button {
  background: white;
  border-radius: 45px;
  padding: 4px;

  border: 2px solid gray;
  transition: 0.1s;
}

td.duplicate-rm-box {
  min-width: 5em;
}

td.duplicate-rm-box button:hover,
.expand-checkbox .expand-arrow i:hover,
.delete-div button:hover {
  border: 2.2px solid dimgray;
  padding: 4.5px;
  color: dimgray;
}

.checkBoxHover {
  opacity: 1;
}

.checkBoxNotHover {
  opacity: 0.4;
}

.container-edit-progress {
  background: white;
}

/* font-size of the table */

@media only screen
and (max-width: 1823px) {
  #instru-table-main-container table tbody td {
    font-size: 8px !important;
  }
}

@media only screen
and (min-width: 1824px) {
  #instru-table-main-container table tbody td {
    font-size: 10px !important;
  }
}

/* special rules for microsoft [F*****G] EDGE  */
@supports (-ms-ime-align: auto) {
  th.constraints-header-table.column.sortable.asc {
    width: unset;
  }

  th.dsp-header-table.column.sortable.asc {
    width: unset;
  }

  .keep-width-expand {
    min-width: 5em;
  }
}
</style>

import {
  $APPNEXUS, $BEESWAX, $DBM, $FACEBOOK, $KAYZEN, $MEDIAMATH, $META,
  $OPTIMIZE_ADGROUPS, $OPTIMIZE_ADSETS,
  $OPTIMIZE_CAMPAIGNS,
  $OPTIMIZE_LINEITEMS,
  $OPTIMIZE_STRATEGIES, $THETRADEDESK, $YOUTUBE
} from '../../../config/dspConfig'
import * as commonUtils from '../../../utils/commonUtils'
import {
  AttributionWindow,
  CustomProdManagementModule,
  DspInstruction,
  InsGetterSetter, InstructionDsp, KpiRollingPeriod,
  MarkupModule, MeasurementToolInstruction, Objective,
  OptimizeKeys, P3ObjWatcher, RevenueTypeInstruction,
  StatusInstruction, TypeOfBudgetInstruction, WaterfallPriority
} from '../../../types/instruction_type'
import { KpiValue } from '../../../types/brief_enum'
import CheckUpdateEntity from '@/models/BaseModel/CheckUpdateEntity'
import VueNestedObject from '@/models/BaseModel/VueNestedObject'
import { ConversionFunnelInstruction } from '../../../types/brief_type'
import { ThirdPartyKpiHelper } from '../../../utils/ThirdPartyKpi/thirdPartyKpiHelper'

export default class InstructionGetterSetter extends CheckUpdateEntity<InstructionGetterSetter> implements InsGetterSetter {
  [key: string]: any
  editedItem: DspInstruction
  dsp: InstructionDsp
  vueNestedObject = new VueNestedObject()

  constructor (io: DspInstruction, dsp: InstructionDsp) {
    super()
    this.editedItem = io
    this.dsp = dsp
    this.saveOriginal()
  }

  /**
   * return a instance of the InstructionGetterSetter
   * but with the original value (the value saved in _originalStringified of the CheckUpdateEntity)
   */
  getOriginalInsGetterSetter (): InstructionGetterSetter {
    return new InstructionGetterSetter(this.getOriginal().editedItem, this.getOriginal().dsp)
  }

  /**
   * for optimize keys
   */
  getOptimizePrefix (field: OptimizeKeys): string {
    if (this.editedItem[field] === undefined || this.editedItem[field] === null || this.editedItem[field] === '') {
      this.reactiveSet(this.editedItem, field, 'all')
      return this.editedItem[field]
    }

    if (this.editedItem[field].trim() === 'all') {
      return this.editedItem[field].trim()
    }

    let splitted = this.editedItem[field].split(',')
    // remove the first element of the array and save him to optimizeType
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    let optimizeType = splitted.shift()
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    return optimizeType.trim()
  }

  /**
   * for optimize keys
   */
  getOptimizeIds (field: OptimizeKeys): string {
    if (this.editedItem[field] === undefined || this.editedItem[field] === null || this.editedItem[field].trim() === 'all') {
      return ''
    }

    let splitted = this.editedItem[field].split(',')
    // remove the first element of the array and save him to optimizeType
    splitted.shift()
    // the last element are the ids
    let optimizedIds = splitted.map(id => id.trim())
    return optimizedIds.join(',')
  }

  setPercentage (value: any) {
    return value !== null && value !== 0 && value !== undefined && value !== ''
      ? (value / 100)
      : value
  }

  getPercentage (toReturn: any) {
    return toReturn !== null && toReturn !== undefined && Number(toReturn) !== 0
      ? Math.round(toReturn * 100)
      : toReturn
  }

  /**
   * optimize_lineitems
   */
  get preLI () {
    return this.getOptimizePrefix($OPTIMIZE_LINEITEMS)
  }
  set preLI (value) {
    this.editedItem.optimize_lineitems = (value === 'all') ? value : value + ' ,' + this.idLi
  }
  get idLi () {
    return this.getOptimizeIds($OPTIMIZE_LINEITEMS)
  }
  set idLi (value) {
    this.editedItem.optimize_lineitems = (this.preLI === 'all') ? 'all' : this.preLI + ' ,' + value
  }

  /**
   * optimize_campaigns
   */
  get preCA () {
    return this.getOptimizePrefix($OPTIMIZE_CAMPAIGNS)
  }
  set preCA (value) {
    this.editedItem.optimize_campaigns = (value === 'all') ? value : value + ' ,' + this.idCa
  }
  get idCa () {
    return this.getOptimizeIds($OPTIMIZE_CAMPAIGNS)
  }
  set idCa (value) {
    this.editedItem.optimize_campaigns = (this.preCA === 'all') ? 'all' : this.preCA + ' ,' + value
  }

  /**
   * optimize_adgroups (thetradedesk)
   */
  get preAD () {
    return this.getOptimizePrefix($OPTIMIZE_ADGROUPS)
  }
  set preAD (value) {
    this.editedItem.optimize_adgroups = (value === 'all') ? value : value + ' ,' + this.idAd
  }
  get idAd () {
    return this.getOptimizeIds($OPTIMIZE_ADGROUPS)
  }
  set idAd (value) {
    this.editedItem.optimize_adgroups = (this.preAD === 'all') ? 'all' : this.preAD + ' ,' + value
  }

  /**
   * optimize_strategies (mediamath)
   */
  get preSTR () {
    return this.getOptimizePrefix($OPTIMIZE_STRATEGIES)
  }
  set preSTR (value) {
    this.editedItem.optimize_strategies = (value === 'all') ? value : value + ' ,' + this.idStr
  }
  get idStr () {
    return this.getOptimizeIds($OPTIMIZE_STRATEGIES)
  }
  set idStr (value) {
    this.editedItem.optimize_strategies = (this.preSTR === 'all') ? 'all' : this.preSTR + ' ,' + value
  }

  /**
   * optimize_adsets (facebook/meta)
   */
  get preADSET () {
    return this.getOptimizePrefix($OPTIMIZE_ADSETS)
  }
  set preADSET (value) {
    this.editedItem[$OPTIMIZE_ADSETS] = (value === 'all') ? value : value + ' ,' + this.idAdSet
  }
  get idAdSet () {
    return this.getOptimizeIds($OPTIMIZE_ADSETS)
  }
  set idAdSet (value) {
    this.editedItem[$OPTIMIZE_ADSETS] = (this.preADSET === 'all') ? 'all' : this.preADSET + ' ,' + value
  }

  /**
   * attribution Item special rules
   * Contain some business logic
   */
  getAttributionWindow (isTrue = false) {
    let pvWindowKey: keyof Objective = isTrue ? 'true_PV_window' : 'PV_window'
    let pcWindowKey: keyof Objective = isTrue ? 'true_PC_window' : 'PC_window'
    /**
     * As set in the DSP : true_PV_window et true_PC_window non set in objective
     */
    if (!commonUtils.issetInObject(this.editedItem, 'objective.' + pvWindowKey) &&
      !commonUtils.issetInObject(this.editedItem, 'objective.' + pcWindowKey)) {
      return 'As set in the DSP'
    }
    /**
     * post click only : true_PV_window set at 0 in objective and true_PC_window not set
     */
    if (!commonUtils.issetInObject(this.editedItem, 'objective.' + pcWindowKey) &&
      commonUtils.issetInObject(this.editedItem, pvWindowKey) && this.editedItem.objective[pvWindowKey] === 0) {
      return 'post click only'
    }
    /**
     * else, true_PV_window and/or true_PC_window are displayed
     */
    return 'other'
  }

  setAttributionWindow (value: AttributionWindow, isTrue = false) {
    let pvWindowKey: keyof Objective = isTrue ? 'true_PV_window' : 'PV_window'
    let pcWindowKey: keyof Objective = isTrue ? 'true_PC_window' : 'PC_window'
    /**
     * As set in the DSP
     */
    if (value === 'As set in the DSP') {
      this.reactiveDelete(this.editedItem.objective, pvWindowKey)
      this.reactiveDelete(this.editedItem.objective, pcWindowKey)
    }
    /**
     * post click only
     */
    if (value === 'post click only') {
      this.reactiveSet(this.editedItem.objective, pvWindowKey, 0)
      this.reactiveDelete(this.editedItem.objective, pcWindowKey)
    }
    /**
     * (other) with PC_window and PV_window set
     */
    if (value === 'other') {
      this.reactiveSet(this.editedItem.objective, pvWindowKey, 0)
      this.reactiveSet(this.editedItem.objective, pcWindowKey, 0)
    }
  }

  get trueAttributionWindow () {
    return this.getAttributionWindow(true)
  }

  set trueAttributionWindow (value) {
    this.setAttributionWindow(value, true)
  }

  get attributionWindow () {
    return this.getAttributionWindow(false)
  }

  set attributionWindow (value: AttributionWindow) {
    this.setAttributionWindow(value, false)
  }

  /**
   * objective.recency
   */
  get itemRecency () {
    return !!commonUtils.issetInObject(this.editedItem, 'objective.recency')
  }
  set itemRecency (value) {
    if (value) {
      this.reactiveSet(this.editedItem.objective, 'recency', 'line_item')
    } else {
      this.reactiveDelete(this.editedItem.objective, 'recency')
    }
  }

  get memberId (): number {
    return this.editedItem.member_id
  }

  set memberId (value: number) {
    this.reactiveSetEditedItem('member_id', value)
  }

  get organizationId (): number {
    return this.editedItem.organization_id
  }

  set organizationId (value: number) {
    this.reactiveSetEditedItem('organization_id', value)
  }

  get partnerIdDBM (): number {
    return this.editedItem.partner_id
  }

  set partnerIdDBM (value: number) {
    // TODO : to not use never
    this.reactiveSetEditedItem('partner_id', (value as never))
  }

  get partnerIdYT (): number {
    return this.editedItem.partner_id
  }

  set partnerIdYT (value: number) {
    // TODO : to not use never
    this.reactiveSetEditedItem('partner_id', (value as never))
  }

  get partnerIdTTD (): string {
    return this.editedItem.partner_id
  }

  set partnerIdTTD (value: string) {
    this.reactiveSetEditedItem('partner_id', (value as never))
  }

  get buzzKey (): string {
    return this.editedItem.buzz_key
  }

  set buzzKey (value: string) {
    this.reactiveSetEditedItem('buzz_key', value)
  }

  get advertiserId (): number {
    return this.editedItem.advertiser_id as number
  }

  set advertiserId (value: number) {
    this.reactiveSetEditedItem('advertiser_id', value)
  }

  get advertiserIdTTD (): string {
    return this.editedItem.advertiser_id as string
  }

  set advertiserIdTTD (value: string) {
    this.reactiveSetEditedItem('advertiser_id', value)
  }

  get businessId (): number {
    return this.editedItem.business_id
  }

  set businessId (value: number) {
    this.reactiveSetEditedItem('business_id', value)
  }

  get businessManagerId (): string {
    return this.editedItem.business_manager_id
  }

  set businessManagerId (value: string) {
    this.reactiveSetEditedItem('business_manager_id', value)
  }

  get campaignId (): number {
    return this.editedItem.campaign_id
  }

  set campaignId (value: number) {
    this.reactiveSetEditedItem('campaign_id', value as never)
  }

  get campaignIdMeta (): string {
    return this.editedItem.campaign_id
  }

  set campaignIdMeta (value: string) {
    this.reactiveSetEditedItem('campaign_id', value as never)
  }

  get accountId (): string {
    return this.editedItem.account_id
  }

  set accountId (value: string) {
    this.reactiveSetEditedItem('account_id', value)
  }

  get adAccountId (): string {
    return this.editedItem.ad_account_id
  }

  set adAccountId (value: string) {
    this.reactiveSetEditedItem('ad_account_id', value)
  }

  get optimizeInsertionOrder (): number {
    return this.editedItem.optimize_insertion_orders
  }

  set optimizeInsertionOrder (value: number) {
    this.reactiveSetEditedItem('optimize_insertion_orders', value)
  }

  get optiRatio (): number {
    return this.editedItem.opti_ratio
  }

  set optiRatio (value: number) {
    this.reactiveSetEditedItem('opti_ratio', value)
  }

  get groupName (): string {
    return this.editedItem.group_name
  }

  set groupName (value: string) {
    this.reactiveSetEditedItem('group_name', value)
  }

  get kpiCpa () {
    return this.editedItem?.objective?.KPI_CPA
  }

  set kpiCpa (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'KPI_CPA', value)
  }

  get offset () {
    return this.editedItem?.objective?.offset
  }

  set offset (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'offset', value)
  }

  get maxCpm () {
    return this.editedItem?.objective?.max_CPM
  }

  set maxCpm (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'max_CPM', value)
  }

  get minViz () {
    return this.getPercentage(this.editedItem?.objective?.min_viz)
  }

  set minViz (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_viz', this.setPercentage(value))
  }

  // eslint-disable-next-line camelcase
  get minViz_select () {
    if (this.dsp === 'dbm') {
      return this.roundSelectMinViz(this.editedItem?.objective?.min_viz)
    }
    return this.editedItem?.objective?.min_viz
  }

  // Round func for dbm min viz
  roundSelectMinViz (value: number): number {
    if (!value || Number(value) > 0.9) { return null }
    const stringified = value.toString()
    if (stringified.length !== 3 || !stringified.startsWith('0.')) {
      let valueToRound = stringified[2] + '.' + stringified.substring(3)
      const test = Math.round(Number(valueToRound)) / 10
      return test
    }
    return value
  }

  // eslint-disable-next-line camelcase
  set minViz_select (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_viz', value)
  }

  get minVideo () {
    return this.getPercentage(this.editedItem.objective.min_video_viz)
  }

  set minVideo (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_video_viz', this.setPercentage(value))
  }

  get truePVwindow () {
    return this.editedItem?.objective?.true_PV_window
  }

  set truePVwindow (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'true_PV_window', value)
  }

  get PVwindow () {
    return this.editedItem?.objective?.PV_window
  }

  set PVwindow (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'PV_window', value)
  }

  get truePCwindow () {
    return this.editedItem?.objective?.true_PC_window
  }

  set truePCwindow (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'true_PC_window', value)
  }

  get PCwindow () {
    return this.editedItem?.objective?.PC_window
  }

  set PCwindow (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'PC_window', value)
  }

  get maxBudget () {
    return this.editedItem?.objective?.max_budget
  }

  set maxBudget (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'max_budget', value)
  }

  get minBudget () {
    return this.editedItem?.objective?.min_budget
  }

  set minBudget (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_budget', value)
  }

  get maxA () {
    return this.editedItem?.constraints_io?.max_A
  }

  set maxA (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'max_A', value)
  }

  get youtubeMinA () {
    return this.editedItem?.constraints_io?.min_A
  }

  set youtubeMinA (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'min_A', value)
  }

  get constraintsMaxCpm () {
    return this.editedItem?.constraints_io?.max_CPM
  }

  set constraintsMaxCpm (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'max_CPM', value)
  }

  get constraintsMinCpm () {
    return this.editedItem?.constraints_io?.min_CPM
  }

  set constraintsMinCpm (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'min_CPM', value)
  }

  get marginDaily () {
    return this.editedItem?.constraints_io?.margin_daily
  }

  set marginDaily (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'margin_daily', value)
  }

  get forceMinBudget () {
    return this.editedItem?.constraints_io?.force_min_budget
  }

  set forceMinBudget (value: boolean) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'force_min_budget', value)
  }

  get expectedMargin () {
    return this.editedItem?.obj_watcher?.margin?.value
  }

  set expectedMargin (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'margin')
    this.reactiveSet(this.editedItem.obj_watcher.margin, 'value', value)
  }

  get highestAllowedCPM () {
    return this.editedItem?.obj_watcher?.max_CPM?.value
  }

  set highestAllowedCPM (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'max_CPM')
    this.reactiveSet(this.editedItem.obj_watcher.max_CPM, 'value', value)
  }

  get highestAllowedCPA () {
    return this.editedItem?.obj_watcher?.CPA?.value
  }

  set highestAllowedCPA (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'CPA')
    this.reactiveSet(this.editedItem.obj_watcher.CPA, 'value', value)
  }

  get trueTargetKpiValue () {
    return this.editedItem?.obj_watcher?.true_KPI_to_optimize?.value
  }

  set trueTargetKpiValue (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'true_KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.true_KPI_to_optimize, 'value', value)
  }

  get targetKpiValue () {
    return this.editedItem?.obj_watcher?.KPI_to_optimize?.value
  }

  set targetKpiValue (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.KPI_to_optimize, 'value', value)
  }

  /**
   * Special case targetKpiValue for second level kpi.
   * When the kpi is first level, the input use the "true" value.
   * Otherwise, use the "classic" value.
   */
  get proxyTargetKpiValue () {
    if (this.isKpiSecondLvl) {
      return this.targetKpiValue
    } else {
      return this.trueTargetKpiValue
    }
  }

  set proxyTargetKpiValue (value: number) {
    if (this.isKpiSecondLvl) {
      this.targetKpiValue = value
      this.trueTargetKpiValue = value
    } else {
      this.trueTargetKpiValue = value
    }
  }

  get trueTargetForOptimizedKpi (): KpiValue | null | undefined {
    return this.editedItem?.obj_watcher?.true_KPI_to_optimize?.KPI
  }

  set trueTargetForOptimizedKpi (value: KpiValue) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'true_KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.true_KPI_to_optimize, 'KPI', value)
  }

  get targetForOptimizedKpi (): KpiValue | null | undefined {
    return this.editedItem?.obj_watcher?.KPI_to_optimize?.KPI
  }

  set targetForOptimizedKpi (value: KpiValue) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.KPI_to_optimize, 'KPI', value)
  }

  get targetKpiObject (): { KPI: KpiValue, value: Number } {
    return { KPI: this.trueTargetForOptimizedKpi, value: this.trueTargetKpiValue }
  }

  get proxyTargetForOptimizedKpi (): KpiValue | null | undefined {
    if (this.isKpiSecondLvl) {
      return this.targetForOptimizedKpi
    } else {
      return this.trueTargetForOptimizedKpi
    }
  }

  set proxyTargetForOptimizedKpi (value: KpiValue) {
    if (this.isKpiSecondLvl) {
      this.targetForOptimizedKpi = value
      this.trueTargetForOptimizedKpi = value
    } else {
      this.trueTargetForOptimizedKpi = value
    }
  }

  get lowestAllowedCPM (): number {
    return this.editedItem?.obj_watcher?.min_CPM?.value
  }

  set lowestAllowedCPM (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'min_CPM')
    this.reactiveSet(this.editedItem.obj_watcher.min_CPM, 'value', value)
  }

  get lowestAllowedViewRate (): number {
    return this.editedItem?.obj_watcher?.min_viz?.value
  }

  set lowestAllowedViewRate (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'min_viz')
    this.reactiveSet(this.editedItem.obj_watcher.min_viz, 'value', value)
  }

  get lowestAllowedCompletionRate (): number {
    return this.editedItem?.obj_watcher?.min_crate?.value
  }

  set lowestAllowedCompletionRate (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'min_crate')
    this.reactiveSet(this.editedItem.obj_watcher.min_crate, 'value', value)
  }

  get typeOfBudgetSaturation (): TypeOfBudgetInstruction {
    return this.editedItem?.obj_watcher?.saturation?.budget_type
  }

  set typeOfBudgetSaturation (value: TypeOfBudgetInstruction) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'saturation')
    this.reactiveSet(this.editedItem.obj_watcher.saturation, 'budget_type', value)
  }

  get campaignMaxDiv (): number {
    return this.editedItem?.constraints_campaign?.max_div
  }

  set campaignMaxDiv (value: number) {
    this.createObjectIfNotSet('constraints_campaign')
    this.reactiveSet(this.editedItem.constraints_campaign, 'max_div', value)
  }

  get campaignMaxMult (): number {
    return this.editedItem?.constraints_campaign?.max_mult
  }

  set campaignMaxMult (value: number) {
    this.createObjectIfNotSet('constraints_campaign')
    this.reactiveSet(this.editedItem.constraints_campaign, 'max_mult', value)
  }

  get trueKpiToOptimize (): KpiValue {
    return this.editedItem.true_KPI_to_optimize
  }

  set trueKpiToOptimize (value: KpiValue) {
    this.reactiveSetEditedItem('true_KPI_to_optimize', value)
  }

  get kpiToOptimize (): KpiValue {
    return this.editedItem.KPI_to_optimize
  }

  set kpiToOptimize (value: KpiValue) {
    this.reactiveSetEditedItem('KPI_to_optimize', value)
  }

  get proxyKpiToOptimize (): KpiValue {
    if (this.isKpiSecondLvl) {
      return this.kpiToOptimize
    } else {
      return this.trueKpiToOptimize
    }
  }

  set proxyKpiToOptimize (value: KpiValue) {
    if (this.isKpiSecondLvl) {
      this.kpiToOptimize = value
    } else {
      this.trueKpiToOptimize = value
    }
  }

  get matchingLi (): string {
    return this.editedItem.matching_li
  }

  set matchingLi (value: string) {
    this.reactiveSetEditedItem('matching_li', value)
  }

  get matchingCp (): string {
    return this.editedItem.matching_cp
  }

  set matchingCp (value: string) {
    this.reactiveSetEditedItem('matching_cp', value)
  }

  get matchingStrat (): string {
    return this.editedItem.matching_strat
  }

  set matchingStrat (value: string) {
    this.reactiveSetEditedItem('matching_strat', value)
  }

  get matchingAd (): string {
    return this.editedItem.matching_adgroup
  }

  set matchingAd (value: string) {
    this.reactiveSetEditedItem('matching_adgroup', value)
  }

  get matchingAdSet (): string {
    return this.editedItem.matching_adsets
  }

  set matchingAdSet (value: string) {
    this.reactiveSetEditedItem('matching_adsets', value)
  }

  get executorVersion (): number {
    return this.editedItem.executor_version
  }

  set executorVersion (value: number) {
    this.reactiveSetEditedItem('executor_version', value)
  }

  get revCPM (): number {
    return this.editedItem?.constraints_io?.rev_CPM
  }

  set revCPM (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'rev_CPM', value)
  }

  get status (): StatusInstruction {
    return this.editedItem.status
  }

  set status (value: StatusInstruction) {
    this.editedItem.status = value
    this.reactiveSetEditedItem('status', value)
  }

  get trueConvMeasurementTool (): MeasurementToolInstruction {
    return this.editedItem.true_conv_measurement_tool
  }

  set trueConvMeasurementTool (value: MeasurementToolInstruction) {
    this.reactiveSetEditedItem('true_conv_measurement_tool', value)
  }

  get convMeasurementTool (): MeasurementToolInstruction {
    return this.editedItem.conv_measurement_tool
  }

  set convMeasurementTool (value: MeasurementToolInstruction) {
    this.reactiveSetEditedItem('conv_measurement_tool', value)
  }

  get maxCutoff (): number {
    return this.editedItem?.constraints_io?.max_cutoff
  }

  set maxCutoff (value: number) {
    this.createObjectIfNotSet('constraints_io')
    this.reactiveSet(this.editedItem.constraints_io, 'max_cutoff', value)
  }

  get clientIdKayzen (): number {
    return this.editedItem.client_id
  }

  set clientIdKayzen (value: number) {
    this.reactiveSetEditedItem('client_id', value)
  }

  get videoVizPartner (): string {
    return this.editedItem?.objective?.video_viz_partner
  }

  set videoVizPartner (value: string) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'video_viz_partner', value)
  }

  get vizPartner (): string {
    return this.editedItem?.objective?.viz_partner
  }

  set vizPartner (value: string) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'viz_partner', value)
  }

  get minVizTtd (): number {
    return this.editedItem?.objective?.min_viz
  }

  set minVizTtd (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_viz', value)
  }

  get minVideoTtd (): number {
    return this.editedItem?.objective?.min_video_viz
  }

  set minVideoTtd (value: number) {
    this.createObjectIfNotSet('objective')
    this.reactiveSet(this.editedItem.objective, 'min_video_viz', value)
  }

  get clientIdPerDsp () {
    switch (this.dsp) {
      case $APPNEXUS:
        return this.memberId
      case $THETRADEDESK:
        return this.partnerIdTTD
      case $DBM:
        return this.partnerIdDBM
      case $MEDIAMATH:
        return this.organizationId
      case $YOUTUBE:
        return this.partnerIdYT
      case $BEESWAX:
        return this.buzzKey
      case $FACEBOOK:
        return this.businessId
      case $KAYZEN:
        return this.clientIdKayzen
      case $META:
        return this.businessManagerId
      default:
        console.warn(`Not implemented dsp ${this.dsp}`)
        return this.memberId
    }
  }

  set clientIdPerDsp (value: any) {
    switch (this.dsp) {
      case $APPNEXUS:
        this.memberId = value
        break
      case $THETRADEDESK:
        this.partnerIdTTD = value
        break
      case $DBM:
        this.partnerIdDBM = value
        break
      case $MEDIAMATH:
        this.organizationId = value
        break
      case $YOUTUBE:
        this.partnerIdYT = value
        break
      case $BEESWAX:
        this.buzzKey = value
        break
      case $FACEBOOK:
        this.businessId = value
        break
      case $KAYZEN:
        this.clientIdKayzen = value
        break
      case $META:
        this.businessManagerId = value
        break
      default:
        console.warn(`Not implemented dsp ${this.dsp}`)
        this.memberId = value
        break
    }
  }

  get advertiserIdPerDsp () {
    switch (this.dsp) {
      case $APPNEXUS:
        return this.advertiserId
      case $THETRADEDESK:
        return this.advertiserIdTTD
      case $FACEBOOK:
        return this.accountId
      case $META:
        return this.adAccountId
      default:
        return this.advertiserId
    }
  }

  set advertiserIdPerDsp (value: any) {
    switch (this.dsp) {
      case $APPNEXUS:
        this.advertiserId = value
        break
      case $THETRADEDESK:
        this.advertiserIdTTD = value
        break
      case $FACEBOOK:
        this.accountId = value
        break
      case $META:
        this.adAccountId = value
        break
      default:
        this.advertiserId = value
        break
    }
  }

  get maxFrequency () {
    return this?.editedItem?.max_frequency
  }
  set maxFrequency (maxFrequency) {
    this.reactiveSet(this.editedItem, 'max_frequency', maxFrequency)
  }

  get targetFrequency () {
    return this?.editedItem?.target_frequency
  }
  set targetFrequency (targetFrequency) {
    this.reactiveSet(this.editedItem, 'target_frequency', targetFrequency)
  }

  get overwriteFrequency () {
    return this?.editedItem?.overwrite_frequency
  }
  set overwriteFrequency (overwriteFrequency) {
    this.reactiveSet(this.editedItem, 'overwrite_frequency', overwriteFrequency)
  }

  get overwriteFrequencyTarget () {
    return this?.editedItem?.overwrite_frequency_target
  }
  set overwriteFrequencyTarget (overwriteFrequencyTarget) {
    this.reactiveSet(this.editedItem, 'overwrite_frequency_target', overwriteFrequencyTarget)
  }

  get revenueType (): RevenueTypeInstruction {
    return this?.editedItem?.revenue_type
  }
  set revenueType (value: RevenueTypeInstruction) {
    this.reactiveSet(this.editedItem, 'revenue_type', value)
  }

  get markupManagementIsInheritedFromSeat () {
    return this?.editedItem?.markup_management_is_inherited_from_seat
  }
  set markupManagementIsInheritedFromSeat (value: boolean) {
    this.reactiveSet(this.editedItem, 'markup_management_is_inherited_from_seat', value)
  }

  get markupModule () {
    return this?.editedItem?.markup_module
  }
  set markupModule (value: MarkupModule) {
    this.reactiveSet(this.editedItem, 'markup_module', value)
  }

  get customProdManagementIsInheritedFromSeat () {
    return this?.editedItem?.custom_prod_management_is_inherited_from_seat
  }
  set customProdManagementIsInheritedFromSeat (value: boolean) {
    this.reactiveSet(this.editedItem, 'custom_prod_management_is_inherited_from_seat', value)
  }

  get customProdManagementModule () {
    return this?.editedItem?.custom_prod_management_module
  }
  set customProdManagementModule (value: CustomProdManagementModule) {
    this.reactiveSet(this.editedItem, 'custom_prod_management_module', value)
  }

  get stratLvl () {
    return this?.editedItem?.strat_lvl
  }

  set stratLvl (value: StratLvl) {
    this.reactiveSet(this.editedItem, 'strat_lvl', value)
  }

  get retry () {
    return this?.editedItem?.retry
  }

  set retry (value: boolean) {
    this.reactiveSet(this.editedItem, 'retry', value)
  }

  get fastRetry () {
    return this?.editedItem?.fast_retry
  }

  set fastRetry (value: boolean) {
    this.reactiveSet(this.editedItem, 'fast_retry', value)
  }

  get reactivateOtto () {
    return this?.editedItem?.reactivate_otto
  }

  set reactivateOtto (value: boolean) {
    this.reactiveSet(this.editedItem, 'reactivate_otto', value)
  }

  get waterfallPriority () {
    return this?.editedItem?.waterfall_priority
  }

  set waterfallPriority (value: WaterfallPriority) {
    this.reactiveSet(this.editedItem, 'waterfall_priority', value)
  }

  get kpiRollingPeriod () {
    return this?.editedItem?.KPI_rolling_period
  }

  set kpiRollingPeriod (value: KpiRollingPeriod) {
    this.reactiveSet(this.editedItem, 'KPI_rolling_period', value)
  }

  get useCustomAlgo () {
    return this.editedItem?.use_custom_algo
  }
  set useCustomAlgo (value: boolean) {
    this.reactiveSet(this.editedItem, 'use_custom_algo', value)
  }

  get ottoMinViz () {
    return this.editedItem?.otto_min_viz
  }
  set ottoMinViz (value: boolean) {
    this.reactiveSet(this.editedItem, 'otto_min_viz', value)
  }

  get optiAuto () {
    return this.editedItem?.opti_auto
  }
  set optiAuto (value: boolean) {
    this.reactiveSet(this.editedItem, 'opti_auto', value)
  }

  get trueTargetKpiValue3rdParty () {
    return this.editedItem?.obj_watcher?.true_KPI_to_optimize?.value_3rd_party
  }

  set trueTargetKpiValue3rdParty (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'true_KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.true_KPI_to_optimize, 'value_3rd_party', value)
  }

  get targetKpiValue3rdParty () {
    return this.editedItem?.obj_watcher?.KPI_to_optimize?.value_3rd_party
  }

  set targetKpiValue3rdParty (value: number) {
    this.createObjectIfNotSet('obj_watcher')
    this.createObjectIfNotSetWithItem(this.editedItem.obj_watcher, 'KPI_to_optimize')
    this.reactiveSet(this.editedItem.obj_watcher.KPI_to_optimize, 'value_3rd_party', value)
  }

  get proxyTargetKpiValue3rdParty () {
    if (this.isKpiSecondLvl) {
      return this.targetKpiValue3rdParty
    } else {
      return this.trueTargetKpiValue3rdParty
    }
  }

  set proxyTargetKpiValue3rdParty (value: number) {
    if (this.isKpiSecondLvl) {
      this.targetKpiValue3rdParty = value
    } else {
      this.trueTargetKpiValue3rdParty = value
    }
  }

  get truePivotVariable () {
    return this.editedItem.true_pivot_variable
  }

  set truePivotVariable (value: string[]) {
    this.reactiveSet(this.editedItem, 'true_pivot_variable', value)
  }

  get conversionFunnelPixelIds () {
    return this.editedItem?.conversion_funnel_pixel_ids
  }

  set conversionFunnelPixelIds (value: ConversionFunnelInstruction) {
    this.reactiveSet(this.editedItem, 'conversion_funnel_pixel_ids', value)
  }

  get trueConversionFunnelPixelIds () {
    return this.editedItem?.true_conversion_funnel_pixel_ids
  }

  set trueConversionFunnelPixelIds (value: ConversionFunnelInstruction) {
    this.reactiveSet(this.editedItem, 'true_conversion_funnel_pixel_ids', value)
  }

  get isKpiSecondLvl () {
    const thirdPartyHelper = new ThirdPartyKpiHelper()
    return thirdPartyHelper.isKpiSecondLvl(this.trueKpiToOptimize)
  }

  get allowSwitchIoPacing () {
    return this.editedItem?.allow_switch_io_pacing
  }

  set allowSwitchIoPacing (value: boolean) {
    this.editedItem.allow_switch_io_pacing = value
  }

  get overwriteLiBudget () {
    return this.editedItem?.overwrite_li_budget
  }

  set overwriteLiBudget (value: boolean) {
    this.editedItem.overwrite_li_budget = value
  }

  get thirdPartyObjWatcher () {
    return this.editedItem?.p3_obj_watcher
  }

  set thirdPartyObjWatcher (value: P3ObjWatcher) {
    this.reactiveSet(this.editedItem, 'p3_obj_watcher', value)
  }

  get thirdPartyObjWatcherPivotVariables () {
    return this.editedItem?.p3_obj_watcher_pivot_variables
  }

  set thirdPartyObjWatcherPivotVariables (value: string[]) {
    this.reactiveSet(this.editedItem, 'p3_obj_watcher_pivot_variables', value)
  }

  get thirdPartyObjWatcherAlpha (): number | '' {
    return this.editedItem?.p3_obj_watcher_alpha
  }

  set thirdPartyObjWatcherAlpha (value: number | '') {
    this.reactiveSet(this.editedItem, 'p3_obj_watcher_alpha', value)
  }

  createObjectIfNotSet (key: ObjectKeys<DspInstruction>) {
    this.createObjectIfNotSetWithItem(this.editedItem, key)
  }

  reactiveSetEditedItem<K extends KeysOf<DspInstruction>> (key: K, value: DspInstruction[K]) {
    this.reactiveSet(this.editedItem, key, value)
  }

  /**
   * proxy function
   */
  reactiveSet<T extends object, K extends KeysOf<T>> (item: T, key: K, value: T[K]) {
    this.vueNestedObject.reactiveSet(item, key, value)
  }
  /**
   * proxy function
   */
  reactiveDelete<T extends object, K extends KeysOf<T>> (item: T, key: K) {
    this.vueNestedObject.reactiveDelete(item, key)
  }
  /**
   * proxy function
   */
  createObjectIfNotSetWithItem<T extends object> (item: T, key: ObjectKeys<T>) {
    this.vueNestedObject.createObjectIfNotSetWithItem(item, key)
  }
}

<template>
  <td class="optimize-fragment">
    <v-row wrap>
        <v-col cols="12" v-for="(item, index) in optimize" :key="index">
          <AlignLabel
            :class="{advancedItem: item.isAdvanced}"
            :little="true"
            :label="item.label"
            :chipStyle="true"
            :blueStyle="true"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                {{ item.value.substring(0,13) }}
                {{ item.value.toString().length >= 13 ? `...` : ``}}
                </span>
              </template>
              <span>{{item.value}}</span>
            </v-tooltip>
          </AlignLabel>
        </v-col>
    </v-row>
  </td>
</template>

<script>
import { AlignLabel } from '../../Tools'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'OptimizeFragment',
  props: ['props', 'config'],
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    optimize: function () {
      let optimizedComputed = this.computedProcess(this.config)

      // advanced filter
      return optimizedComputed.filter(item => {
        let defaultItem = this.$store.getters.getDefaultItem
        return !item.isAdvanced || (item.isAdvanced && item.value.trim() !== defaultItem[item.valueFromField])
      })
    }
  },
  watch: {

  }
}
</script>

<style>
.optimize-fragment {
  width: 20em;
}
</style>

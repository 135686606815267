<template>
<span>
  <span :style="mini === true ? `font-size:10px` : ``" class="label-table-line" v-html="label"></span>
  <slot></slot>
</span>
</template>

<script>
export default {
  name: 'LabelLine',
  props: {
    label: [String, Number],
    mini: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
  /*.label-table-line {*/
  /*  font-weight: 500;*/
  /*  font-size: 8px !important;*/
  /*}*/
</style>

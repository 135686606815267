import { times } from 'lodash'
import {
  RemoveScibidsApiArgs,
  RemoveScibidsRemovalParametersApiArgs,
  RemoveScibidsApplyStrategyApiArgs
} from '../../../types/remove_scibids_types'

export class RemoveScibidsApplyStrategyModel {
  comboSelected: number
  baseBid: number
  dspObjective: string
  dspObjectiveTarget: number

  constructor (data: RemoveScibidsApplyStrategyApiArgs = null) {
    if (data != null) {
      this.comboSelected = data.combo_selected
      this.baseBid = data.base_bid
      this.dspObjective = data.dsp_objective
      this.dspObjectiveTarget = data.dsp_objective_target
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.comboSelected = null
    this.baseBid = null
    this.dspObjective = null
    this.dspObjectiveTarget = null
  }
  resetValues () {
    this.baseBid = null
    this.dspObjective = null
    this.dspObjectiveTarget = null
  }
  getAsApiArgs (): RemoveScibidsApplyStrategyApiArgs {
    const toReturn: RemoveScibidsApplyStrategyApiArgs = {
      combo_selected: this.comboSelected,
      base_bid: this.baseBid,
      dsp_objective: this.dspObjective,
      dsp_objective_target: this.dspObjectiveTarget
    }
    let cleanedToReturn: RemoveScibidsApplyStrategyApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== null &&
        toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== undefined &&
        toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] = toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs]
      }
    }
    return cleanedToReturn
  }
}

export class RemoveScibidsRemovalParametersModel {
  deactivateAfterRemove: boolean
  applyStrategy: RemoveScibidsApplyStrategyModel

  constructor (data: RemoveScibidsRemovalParametersApiArgs = null) {
    if (data != null) {
      this.deactivateAfterRemove = data.deactivate_after_remove
      this.applyStrategy = new RemoveScibidsApplyStrategyModel(data.apply_strategy)
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.deactivateAfterRemove = false
    this.applyStrategy = new RemoveScibidsApplyStrategyModel()
  }
  getAsApiArgs (): RemoveScibidsRemovalParametersApiArgs {
    const toReturn: RemoveScibidsRemovalParametersApiArgs = {
      deactivate_after_remove: this.deactivateAfterRemove,
      apply_strategy: this.applyStrategy.getAsApiArgs()
    }
    let cleanedToReturn: RemoveScibidsRemovalParametersApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] !== null &&
        toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] !== undefined) {
        // @ts-ignore
        cleanedToReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] = toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs]
      }
    }
    return cleanedToReturn
  }
}

export default class RemoveScibidsModel {
  id: number
  removalReason: string
  removalParameters: RemoveScibidsRemovalParametersModel

  constructor (data: RemoveScibidsApiArgs = null) {
    if (data != null) {
      this.id = data.id
      this.removalReason = data.removal_reason
      this.removalParameters = new RemoveScibidsRemovalParametersModel(data.removal_parameters)
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.id = null
    this.removalReason = null
    this.removalParameters = new RemoveScibidsRemovalParametersModel()
  }
  getAsApiArgs (): RemoveScibidsApiArgs {
    const toReturn: RemoveScibidsApiArgs = {
      id: this.id,
      removal_reason: this.removalReason,
      removal_parameters: this.removalParameters.getAsApiArgs()
    }

    let cleanedToReturn: RemoveScibidsApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof RemoveScibidsApiArgs] !== null &&
        toReturn[key as keyof RemoveScibidsApiArgs] !== undefined &&
        toReturn[key as keyof RemoveScibidsApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof RemoveScibidsApiArgs] = toReturn[key as keyof RemoveScibidsApiArgs]
      }
    }

    return cleanedToReturn
  }
}

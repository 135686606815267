import Vue from 'vue'
import Component from 'vue-class-component'
import {
  $APPNEXUS,
  $BEESWAX,
  $DBM,
  $MEDIAMATH,
  $THETRADEDESK,
  $YOUTUBE,
  $FACEBOOK,
  $KAYZEN,
  $META
} from '../../config/dspConfig'
import { InstructionDsp } from 'types/instruction_type'

@Component({})
export class activateMixin extends Vue {
  dsp: InstructionDsp

  get activateDSPoverwriting () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $YOUTUBE, $FACEBOOK, $BEESWAX, $META].indexOf(this.dsp) !== -1
  }
  get activateConstraintsToRespect () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $FACEBOOK, $BEESWAX, $YOUTUBE, $KAYZEN, $META].indexOf(this.dsp) !== -1
  }
  get activateAlgoStrategy () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $YOUTUBE, $FACEBOOK, $BEESWAX].indexOf(this.dsp) !== -1
  }
  get activateInheritExpectedMargin () {
    return [$DBM, $APPNEXUS, $MEDIAMATH, $THETRADEDESK, $BEESWAX].indexOf(this.dsp) !== -1
  }
  get activateInheritHighest () {
    return [$DBM, $APPNEXUS, $MEDIAMATH, $THETRADEDESK, $BEESWAX].indexOf(this.dsp) !== -1
  }
  get activateInheritLowest () {
    return [$DBM, $APPNEXUS, $MEDIAMATH, $THETRADEDESK, $BEESWAX].indexOf(this.dsp) !== -1
  }
  get activateRecency () {
    return [$APPNEXUS].indexOf(this.dsp) !== -1
  }
  get activateMaxA () {
    return [$DBM, $APPNEXUS, $YOUTUBE, $MEDIAMATH, $BEESWAX, $THETRADEDESK].indexOf(this.dsp) !== -1
  }
  get activateMaxCutoff () {
    return [$DBM, $APPNEXUS, $YOUTUBE, $MEDIAMATH, $BEESWAX, $THETRADEDESK].indexOf(this.dsp) !== -1
  }
  get activateExecutor () {
    return false
  }
  get activateConversionFunnel () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $BEESWAX, $FACEBOOK, $KAYZEN].indexOf(this.dsp) !== -1
  }
  get activateAttributionWindow () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $BEESWAX, $FACEBOOK, $KAYZEN].indexOf(this.dsp) !== -1
  }
  get activateUntie () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $BEESWAX, $YOUTUBE, $KAYZEN].indexOf(this.dsp) !== -1
  }
  get activateMatching () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $FACEBOOK].indexOf(this.dsp) !== -1
  }
  get activateShowAdvancedFunnel () {
    return [$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $BEESWAX, $YOUTUBE].indexOf(this.dsp) !== -1
  }
  get activateMaxDivMaxMult () {
    return [$FACEBOOK].indexOf(this.dsp) !== -1
  }
  get activateRetryToggle () {
    return ![$YOUTUBE].includes(this.dsp)
  }
  get activateWaterfallManagement () {
    return ![$FACEBOOK, $META].includes(this.dsp)
  }
  get activateOttoMinViz () {
    return [$DBM, $APPNEXUS, $THETRADEDESK].includes(this.dsp)
  }
  get activateMarkupModule () {
    return ![$META].includes(this.dsp)
  }
  get activateCustomProdMgmt () {
    return ![$META, $YOUTUBE].includes(this.dsp)
  }

  get activate3rdPartyConstraints () {
    return ![$META, $YOUTUBE].includes(this.dsp)
  }
}

import Vue from 'vue'
import Component from 'vue-class-component'
import { getIoField } from '../../utils/instructionsUtils'

@Component({})
export class callInstructionsOptiMixin extends Vue {
  loadingInstruOpti: boolean = false
  instructionsOpti: any = null

  async callInstructionOptiReturnValue (insertionOrderId: string, cancel = true) {
    await this.callInstructionOpti(insertionOrderId, cancel)
    return this.instructionsOpti
  }

  async callInstructionOpti (insertionOrderId: string, cancel = true) {
    this.loadingInstruOpti = true
    const dsp = this.$route.params.dsp
    const insertionOrderField = getIoField(dsp)
    const response = await this.$apiCaller.getInstructions({ [insertionOrderField]: insertionOrderId, dsp: dsp }, cancel)
    if (!this.$apiCaller.isResponseError(response)) {
      this.instructionsOpti = (response as any).data
    } else {
      this.$store.commit('setErrorMessageWithResponse', response)
    }
    this.loadingInstruOpti = false
  }
}

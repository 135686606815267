<template>
  <td class="constraints-to-respect-td">
    <v-row no-gutters wrap>
      <v-col v-for="(item, index) in constraintsToRespect" :key="index" cols="12">
        <AlignLabel
        :label="item.label"
        :long="true"
        :chipStyle="false"
        :style="item.style && item.style !== '' ? `color: ${item.style}` : ''"
        >
          {{displayPercentageIfNeeded(item)}}
        </AlignLabel>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </td>
</template>

<script>
import { AlignLabel } from '../../Tools'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'ConstraintsToRespect',
  props: ['props'],
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {
      /**
       * use 'only' in item for apply the line only for the dsp in only
       */
      constraints: [
        {
          label: 'expected margin daily',
          value: 'obj_watcher.margin.value',
          isPercentage: true
        },
        {
          label: 'highest allowed CPA',
          value: 'obj_watcher.CPA.value',
          only: [this.$FACEBOOK]
        },
        {
          label: (prop) => {
            return `Targeted : ${prop.item.obj_watcher.true_KPI_to_optimize.KPI}`
          },
          value: 'obj_watcher.true_KPI_to_optimize.value',
          isPercentage: (prop) => {
            return ['CTR', 'VTR'].indexOf(prop.item.obj_watcher.true_KPI_to_optimize.KPI) !== -1
          },
          style: (prop) => {
            if (prop.item.obj_watcher.true_KPI_to_optimize.KPI !== prop.item.true_KPI_to_optimize) {
              return '#3486D7'
            }
            return ''
          }
        },
        {
          label: 'highest allowed CPM',
          value: 'obj_watcher.max_CPM.value'
        },
        {
          label: 'lowest allowed CPM',
          value: 'obj_watcher.min_CPM.value'
        },
        {
          label: 'lowest allowed view rate',
          value: 'obj_watcher.min_viz.value',
          isPercentage: true
        },
        {
          label: 'lowest allowed completion rate',
          value: 'obj_watcher.min_crate.value',
          isPercentage: true,
          only: [this.$APPNEXUS]
        },
        {
          label: 'type of budget evaluated by saturation',
          value: 'obj_watcher.saturation.budget_type'
        },
        {
          label: 'rev CPM',
          value: 'constraints_io.rev_CPM'
        }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    displayPercentageIfNeeded (item) {
      if (!item.isPercentage) {
        return item.value
      }
      return item.value !== 0 ? this.$roundPercentage(item.value).toString() + ' %' : item.value.toString() + ' %'
    }
  },
  computed: {
    constraintsToRespect: function () {
      return this.computedProcess(this.constraints.filter((item) => {
        return item.only === undefined || item.only.indexOf(this.dsp) !== -1
      }))
    }
  },
  watch: {

  }
}
</script>

<style>
</style>

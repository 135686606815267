<template>
  <td class="info-td cursor-default">
    <v-row wrap>
        <v-col cols="12">
          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-icon v-on="on" :class="(getActiveByDsp(props) ? 'green-lens' : 'red-lens')" small>lens</v-icon>
              </template>
              <span> Active : {{getActiveByDsp(props) == null ? 'false' : getActiveByDsp(props).toString()}} </span>
          </v-tooltip>
        </v-col>
    </v-row>
  </td>
</template>

<script>
export default {
  name: 'InfoInstructionsFragment',
  props: ['props'],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    getActiveByDsp (prop) {
      return prop.item.is_active
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>

<template>
  <td>
    <v-row align-center justify-centerfill-height class="expand-checkbox">
      <div v-if="useCheckBox">
        <v-checkbox
            primary
            hide-details
            v-model="selected[props.item.id]"
            :class="isHover || selected[props.item.id] ? 'checkBoxHover' : 'checkBoxNotHover'"
        ></v-checkbox>
      </div>
      <div v-if="useExpand" class="expand-arrow" @click="props.expanded = !props.expanded">
        <v-icon v-if="!props.expanded" small>expand_more</v-icon>
        <v-icon v-if="props.expanded" small>expand_less</v-icon>
      </div>
    </v-row>
  </td>
</template>

<script>
export default {
  name: 'ExpandFragment',
  props: ['props', 'selected', 'useCheckBox', 'useExpand', 'isHover'],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>

import { AdvertiserBulkAdd, OverviewApiArgs } from '../../../types/overview_types'

export default class OverviewModel {
  id: number = null
  advertiser: AdvertiserBulkAdd = null
  externalId: string = null
  name: string = null
  status: string = null
  lastStatusUpdate: Date = null
  billingStart: Date = null
  billingEnd: Date = null
  showDefault: boolean = null
  dspActivityStatus: boolean = null
  url: string = null
  subDsp: string = null

  constructor (overviewApiObject: OverviewApiArgs = null) {
    if (overviewApiObject !== null) {
      this.id = overviewApiObject.id
      this.advertiser = overviewApiObject.advertiser
      this.externalId = overviewApiObject.external_id
      this.name = overviewApiObject.name
      this.status = overviewApiObject.status
      this.lastStatusUpdate = overviewApiObject.last_status_update
      this.billingStart = overviewApiObject.billing_start
      this.billingEnd = overviewApiObject.billing_end
      this.showDefault = overviewApiObject.show_default
      this.dspActivityStatus = overviewApiObject.dsp_activity_status
      this.url = overviewApiObject.url
      this.subDsp = overviewApiObject.sub_dsp
    }
  }

  getAsApiArgs (): Partial<OverviewApiArgs> {
    const toReturn: OverviewApiArgs = {
      id: this.id,
      advertiser: this.advertiser,
      external_id: this.externalId,
      name: this.name,
      status: this.status,
      last_status_update: this.lastStatusUpdate,
      billing_start: this.billingStart,
      billing_end: this.billingEnd,
      show_default: this.showDefault,
      dsp_activity_status: this.dspActivityStatus,
      url: this.url,
      sub_dsp: this.subDsp
    }

    let cleanedToReturn: Partial<OverviewApiArgs> = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof OverviewApiArgs] !== null &&
        toReturn[key as keyof OverviewApiArgs] !== undefined &&
        toReturn[key as keyof OverviewApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof OverviewApiArgs] = toReturn[key as keyof OverviewApiArgs]
      }
    }
    return cleanedToReturn
  }
}

<template>
  <td>
    <AlignLabel
      v-if="maxA !== null"

      :label="'Max_A : '"
      :chipStyle="false"
      >
      {{maxA}}
    </AlignLabel>
    <AlignLabel
      v-if="maxCutoff !== null"

      :label="'Max Cutoff : '"
      :chipStyle="false"
    >
      {{maxCutoff}}
    </AlignLabel>
    <v-spacer></v-spacer>
    <AlignLabel
      v-if="displayMarginDaily"
      :label="'Expected margin (%)'"
      :chipStyle="false"

      class="advancedItem"
    >
    {{marginDaily}} %
    </AlignLabel>
    <AlignLabel
      v-if="displayConstraintsIoMaxCpm"
      :label="'Max CPM : '"
      :chipStyle="false"

      class="advancedItem"
    >
      {{props.item.constraints_io.max_CPM}}
    </AlignLabel>
    <AlignLabel
      v-if="displayConstraintsIoMinCpm"
      :label="'Min CPM : '"
      :chipStyle="false"

      class="advancedItem"
    >
      {{props.item.constraints_io.min_CPM}}
    </AlignLabel>
    <AlignLabel
       v-if="itemRecency"
      :label="'Item recency'"
      :chipStyle="false"

      class="advancedItem"
    >
      {{itemRecency.toString()}}
    </AlignLabel>
    <AlignLabel
       v-if="matchingIdActivated"
      :label="'Matching id requested'"
      :chipStyle="false"

      class="advancedItem"
    >
      {{matchingIdActivated.toString()}}
    </AlignLabel>

    <AlignLabel
      v-if="maxMult !== null && maxMult !== undefined"
      :label="'Max mult : '"
      :chipStyle="false"

      class="advancedItem"
    >
      {{maxMult}}
    </AlignLabel>

    <AlignLabel
      v-if="maxDiv !== null && maxDiv !== undefined"
      :label="'Max div : '"
      :chipStyle="false"

      class="advancedItem"
    >
      {{maxDiv}}
    </AlignLabel>
  </td>
</template>

<script>
import { AlignLabel } from '../../Tools'
import { generalMixin } from '../../../../mixins/generalMixin'

export default {
  name: 'AlgoStrategy',
  props: ['props'],
  mixins: [generalMixin],
  components: {
    AlignLabel
  },
  data: function () {
    return {

    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {
    maxA: function () {
      if (!this.checkElement(this.props.item.constraints_io) || !this.checkElement(this.props.item.constraints_io.max_A)) {
        return null
      }

      return this.props.item.constraints_io.max_A
    },
    maxCutoff: function () {
      if (!this.checkElement(this.props.item.constraints_io) || !this.checkElement(this.props.item.constraints_io.max_cutoff)) {
        return null
      }

      return this.props.item.constraints_io.max_cutoff
    },
    displayMarginDaily: function () {
      if (this.$isNullOrUndefined(this.props.item.constraints_io) || this.$isNullOrUndefined(this.props.item.obj_watcher)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.constraints_io.margin_daily)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.obj_watcher.margin) && this.$isNotNullOrUndefined(this.props.item.constraints_io.margin_daily)) {
        return true
      }

      return this.props.item.constraints_io.margin_daily !== this.props.item.obj_watcher.margin.value
    },
    marginDaily: function () {
      return this.$isNotNullOrUndefined(this.props.item.constraints_io.margin_daily)
        ? Math.round(this.props.item.constraints_io.margin_daily * 100)
        : ''
    },
    displayConstraintsIoMaxCpm: function () {
      if (this.$isNullOrUndefined(this.props.item.constraints_io) || this.$isNullOrUndefined(this.props.item.obj_watcher)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.constraints_io.max_CPM)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.obj_watcher.max_CPM) && this.$isNotNullOrUndefined(this.props.item.constraints_io.max_CPM)) {
        return true
      }

      return this.props.item.constraints_io.max_CPM !== this.props.item.obj_watcher.max_CPM.value
    },
    displayConstraintsIoMinCpm: function () {
      if (this.$isNullOrUndefined(this.props.item.constraints_io) || this.$isNullOrUndefined(this.props.item.obj_watcher)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.constraints_io.min_CPM)) {
        return false
      }

      if (this.$isNullOrUndefined(this.props.item.obj_watcher.min_CPM) && this.$isNotNullOrUndefined(this.props.item.constraints_io.min_CPM)) {
        return true
      }

      return this.props.item.constraints_io.min_CPM !== this.props.item.obj_watcher.min_CPM.value
    },
    itemRecency: function () {
      return this.$commonUtils.issetInObject(this.props.item, 'objective.recency')
    },
    matchingIdActivated: function () {
      let dsp = this.$route.params.dsp

      let matchingFields = this.$dspConfig[dsp].matchingFields

      return matchingFields.reduce((acc, curr) => {
        return acc || (this.$isNotNullOrUndefined(this.props.item[curr]) && this.props.item[curr].trim() !== '')
      }, false)
    },
    maxMult () {
      return this.$commonUtils.issetInObject(this.props.item, 'constraints_campaign.max_mult') ? this.props.item.constraints_campaign.max_mult : null
    },
    maxDiv () {
      return this.$commonUtils.issetInObject(this.props.item, 'constraints_campaign.max_div') ? this.props.item.constraints_campaign.max_div : null
    }
  },
  watch: {

  }
}
</script>

<style>
</style>
